<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
        <mat-icon class="close-x-primary">close</mat-icon>
    </button>
</div>

<div class="pb-2 text-center title-text-modal">
    {{ newQuest ? ('newTest' | translate) : ('editTestTitle' | translate) }}
</div>

<form [formGroup]="miFormulario">
    <div #formContainer class="md:px-6 px-0 form-container" cdkDropList (cdkDropListDropped)="drop($event)">

        <div class="pt-6 pb-3 grid grid-cols-1 md:grid-cols-2 items-end gap-3">
            <div tourAnchor="catalogs.job.quest.add.modal" class="flex flex-col">
                <label class="pr-6 pt-2 green-subtitle" for="nombre">
                    {{ 'testTitle' | translate }}*
                </label>
                <div class="flex">
                    <input id="testTitle" formControlName="nombre" class="p-2 w-full mt-1 borde" type="text"
                        name="nombre" maxlength="50"
                        oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/(),"]+/g, "").trimStart();' />
                </div>
                <div *ngIf="miFormulario.get('nombre').invalid && (miFormulario.get('nombre').dirty || miFormulario.get('nombre').touched)"
                    class="text-red-500 text-xs mt-1 flex md:hidden">
                    <span *ngIf="miFormulario.get('nombre').errors['required']">
                        {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('testTitle' | translate) } }}
                    </span>
                    <span *ngIf="miFormulario.get('nombre').errors['noSpecialCharacters']">
                        {{ 'invalidCharactersKeyFormCatalogs' | translate }}
                    </span>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div class="flex flex-col">
                    <label class="pr-6 question-label" for="percent2">
                        {{ 'percentJob2' | translate }}
                    </label>
                    <div class="flex items-center gap-2">
                        <input id="percent2" formControlName="percent2" class="p-2 w-full mt-1 borde" type="text"
                            name="percent2" maxlength="3"
                            oninput="this.value = this.value === '' ? '' : Math.min(100, Math.max(1, this.value.replace(/[^0-9]/g, '')));" /><span
                            class="percent-ico">%</span>
                    </div>
                </div>
                <div class="flex flex-col">
                    <label class="pr-6 question-label" for="percent1">
                        {{ 'percentJob1' | translate }}
                    </label>
                    <div class="flex items-center gap-2">
                        <input id="percent1" formControlName="percent1" class="p-2 w-full mt-1 borde" type="text"
                            name="percent1" maxlength="3"
                            oninput="this.value = this.value === '' ? '' : Math.min(100, Math.max(1, this.value.replace(/[^0-9]/g, '')));" /><span
                            class="percent-ico">%</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-red-500 text-xs grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
                <div class="hidden md:flex"
                    *ngIf="miFormulario.get('nombre').invalid && (miFormulario.get('nombre').dirty || miFormulario.get('nombre').touched)">
                    <span *ngIf="miFormulario.get('nombre').errors['required']">
                        {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('testTitle' | translate) } }}
                    </span>
                    <span *ngIf="miFormulario.get('nombre').errors['noSpecialCharacters']">
                        {{ 'invalidCharactersKeyFormCatalogs' | translate }}
                    </span>
                </div>
            </div>
            <div *ngIf="miFormulario.hasError('biggerThanPercent1')">
                <span>
                    {{ 'percentError' | translate }}
                </span>
            </div>
        </div>

        <div tourAnchor="catalogs.job.quest.add.modal.description" class="py-2">
            <label class="pr-6 pt-1 green-subtitle" for="descripcion">{{ 'testDescription'
                | translate }}</label>
            <textarea id="testDescription" class="p-2 w-full mt-1 borde" type="text" name="descripcion" rows="4"
                formControlName="descripcion" maxlength="4000"
                oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/(),"]+/g, "").trimStart();'></textarea>

            <div *ngIf="miFormulario.get('descripcion').invalid && (miFormulario.get('descripcion').dirty || miFormulario.get('descripcion').touched)"
                class="text-red-500 text-xs mt-1">
                <span *ngIf="miFormulario.get('descripcion').errors['noSpecialCharacters']">
                    {{ 'invalidCharactersKeyFormCatalogs' | translate }}
                </span>
            </div>
        </div>

        <!-- Questions -->
        <div cdkDropList class="py-2" (cdkDropListDropped)="dropQuestion($event)">
            <div class="w-full" *ngFor="let question of questionnaire.questions; let i = index">
                <app-question-form-card *ngIf="question" [question]="question" [index]="i"
                    [seted]="getSetedQuestions(question, i)" [deleteAvailable]="questionnaire.questions.length > 1"
                    (delete)="deleteQuestion(i)" (duplicate)="duplicateQuestion(i)"></app-question-form-card>
            </div>
        </div>
    </div>

    <div class="md:px-6 px-0 flex justify-between pt-4">
        <app-custom-primary-button tourAnchor="catalogs.job.quest.add.modal.questionAdd" id="addQuestion"
            size="filter-size" color="secondary" class="text-center" [textPadding]="'8px'" [textAlign]="'inherit'"
            (click)="addQuestion()">
            <span class="btn-label">{{ 'addQuestion' | translate }}</span>
        </app-custom-primary-button>
        <app-custom-primary-button tourAnchor="catalogs.job.quest.add.modal.questionSave" id="saveQuestions"
            type="submit" size="filter-size" color="primary" class="text-center" [textPadding]="'8px'"
            [textAlign]="'inherit'" (click)="createOrUpdateQuestionnaire()">
            <div class="w-full flex justify-center gap-2">
                <mat-spinner *ngIf="sending" diameter="20" color="primary"></mat-spinner>
                <span class="btn-label">{{ 'saveQuestions' | translate }}</span>
            </div>
        </app-custom-primary-button>
    </div>
</form>