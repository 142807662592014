import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Candidate } from "src/app/interfaces/Candidate";
import { candidateStatus } from "src/app/interfaces/candidateStatus";
import { Status } from "src/app/interfaces/status";
import { UpdateCandidateStaPro } from "src/app/interfaces/UpdateCandidateStaPro";
import { LocalService } from "src/app/services/local.service";
import { PuzzleService } from "src/app/services/puzzle.service";
import { ResponseDialogComponent } from "../response-dialog/response-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { MatSelectChange } from "@angular/material/select";
import { forkJoin } from "rxjs";
import { ReasonForDiscard } from "src/app/interfaces/reason-for-discard";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Router } from "@angular/router";
import { APIResponse } from "src/app/interfaces/response";

@Component({
  selector: "app-change-status",
  templateUrl: "./change-status.component.html",
  styleUrls: ["./change-status.component.css"],
})
export class ChangeStatusComponent implements OnInit {

  @ViewChild('autocompleteTrigger') autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('inputElement') inputElement: ElementRef;
  isUserMentioned: boolean = false;
  previousInputValue: string = '';
  isAutocompleteOpen: boolean = false;
  candidate: Candidate;
  progressId: string;
  progressSelected: Status;
  progressList: Status[] = [];
  allUsers: any[] = [];
  usuarios = [];
  selectedUserNames: string[] = [];
  mentionedUserIds: string[] = [];
  usuariosFiltrados = this.usuarios;
  formattedComment: string = '';
  currentUser: string;
  statusId: string;
  statusSelected: candidateStatus;
  statusList: candidateStatus[] = [];
  reasonsList: ReasonForDiscard[] = [];
  progressMode = 0;
  progressHeader: string = "Cambiar Estatus";
  id: string = "";
  hiredVacancyDate: Date;
  minDate: any;
  sending: boolean = false;
  showCatalogsEmptyMessage: boolean = false;
  sendInstantMail: boolean = false;
  hasSubdomain: boolean = false;
  statusTranslationMap = {
    'En proceso': 'enproceso',
    'Descartado': 'descartado',
    'Cartera': 'cartera',
    'Contratado': 'contratado'
  };
  params = new FormGroup({
    progress: new FormControl(),
    status: new FormControl(),
    name: new FormControl(),
    dropSource: new FormControl()
  });
  token: APIResponse = this.localService.getJsonValue('token');

  constructor(
    public dialog: MatDialog,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private dialogRef: MatDialogRef<ChangeStatusComponent>,
    private translate: TranslateService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) { data, mode }
  ) {
    this.currentUser = this.localService.getJsonValue('token').data.id.toLowerCase();
    this.candidate = data;
    if (mode == 0) {
      this.progressMode = 0;
      this.translate.get('employePhaseAlert').subscribe((res: string) => {
        this.progressHeader = res;
      }); this.id = data.cdT_STATUS_INFO.stS_ID;
    } else {
      this.progressMode = 1;
      this.translate.get('employeStatusAlert').subscribe((res: string) => {
        this.progressHeader = res;
      });
      this.id = data.cdT_STATUS_CANDIDATE_INFO.stsC_ID;
      if (data.cdT_ENTRY_DATE) {
        this.hiredVacancyDate = data.cdT_ENTRY_DATE.toString().split('T')[0];
      } else {
        this.hiredVacancyDate = data.cdT_ENTRY_DATE;
      }
    }
  }

  ngOnInit(): void {
    this.getCatalogs();
    // fecha minima un mes atras
    this.minDate = new Date();
    this.minDate.setMonth(this.minDate.getMonth() - 1);
    this.minDate = this.minDate.toISOString().split('T')[0];

    this.hasSubdomain = this.token.data.comP_HAS_SUBDOMAIN;
  }

  getUsers(): void {
    if (this.usuarios.length === 0) {
      this.puzzleService.getAllUsers(100, 0, null, 'Activos', this.id, null, true).subscribe(users => {

        this.allUsers = users.map(user => ({
          id: user.id.toLowerCase(),
          nombre: user.anU_FULL_NAME,
          email: user.email
        }));

        this.usuarios = this.allUsers.filter(user => user.id !== this.currentUser)

        this.usuariosFiltrados = [...this.usuarios];
      }, error => {
        console.error(error)
      });
    }
  }

  onStatusChange() {
    if (this.params.controls['status'].value?.stsC_ID == 'e7630126-4719-4e8d-8ab7-8146d06bc6ff') {
      if (this.reasonsList.length == 0) {
        this.showCatalogsEmptyMessage = true;
      } else {
        this.showCatalogsEmptyMessage = false;
      }
    } else {
      this.showCatalogsEmptyMessage = false;
    }
  }

  goToReasons() {
    const token = this.localService.getJsonValue('token');
    const companyId = token.data.companyId;
    this.router.navigate(['/home/catalogues/catalogs'], { queryParams: { id: companyId, currentSelection: 'button7' } }).then(() => {
      this.dialogRef.close();
    });
  }

  onEnterPress(event: KeyboardEvent): void {
    if (this.isAutocompleteOpen && this.usuariosFiltrados.length > 0) {
      const firstUser = this.usuariosFiltrados[0];
      this.onUserSelect(firstUser.nombre);
      event.preventDefault();
      this.isAutocompleteOpen = false; // Indica que el panel debe estar cerrado
      this.autocompleteTrigger.closePanel(); // Cierra el panel de autocompletado
    }
  }

  onInput(event: any): void {
    const currentText = this.params.get('name').value;
    this.checkAndUpdateSelectedUsers(currentText);
    const cursorPosition = event.target.selectionStart;
    const textBeforeCursor = currentText.substring(0, cursorPosition);
    const lastAtPos = textBeforeCursor.lastIndexOf('@');

    if (lastAtPos !== -1) {
      const wordAfterAt = currentText.substring(lastAtPos + 1, cursorPosition);
      if (this.usuarios.length === 0) {
        this.getUsers();
      }
      this.usuariosFiltrados = this.usuarios.filter(usuario =>
        usuario.nombre.toLowerCase().includes(wordAfterAt.toLowerCase()) &&
        !this.selectedUserNames.includes(usuario.nombre)
      );
    } else {
      this.usuariosFiltrados = [];
    }

    this.formatComment(this.params.get('name').value);
  }

  checkAndUpdateSelectedUsers(currentText: string): void {
    let newSelectedUsers = [];
    for (let userName of this.selectedUserNames) {
      const userMention = `@${userName}`;
      if (currentText.includes(userMention)) {
        newSelectedUsers.push(userName);
      }
      this.mentionedUserIds
    }

    this.selectedUserNames = newSelectedUsers;
    this.mentionedUserIds = this.usuarios
      .filter(u => this.selectedUserNames.includes(u.nombre))
      .map(u => u.id);
    this.updateFilteredUsers();
  }

  updateFilteredUsers(): void {
    this.usuariosFiltrados = this.usuarios.filter(usuario =>
      !this.selectedUserNames.includes(usuario.nombre)
    );
  }

  formatComment(comment: string): void {
    let formatted = comment;
    this.selectedUserNames.forEach(name => {
      const regex = new RegExp(`@${name}`, 'g');
      formatted = formatted.replace(regex, `<span class="tagged-user">@${name}</span>`);
    });

    this.formattedComment = formatted;
  }

  onUserSelect(selectedUserName: string): void {
    let textarea = this.inputElement.nativeElement;
    let start = textarea.selectionStart;
    let text = this.params.get('name').value;
    let lastAtPos = text.lastIndexOf('@', start - 1)
    let beforeText = text.substring(0, lastAtPos);
    let afterText = text.substring(start, text.length);
    let newText = `${beforeText}@${selectedUserName} ${afterText}`;

    if (!this.selectedUserNames.includes(selectedUserName)) {
      this.selectedUserNames.push(selectedUserName);
      const selectedUser = this.usuarios.find(user => user.nombre === selectedUserName);
      if (selectedUser && !this.mentionedUserIds.includes(selectedUser.id)) {
        this.mentionedUserIds.push(selectedUser.id);
      }
    }

    this.updateFilteredUsers();
    setTimeout(() => {
      this.params.get('name').setValue(newText, { emitEvent: true });
      let newCursorPosition = beforeText.length + selectedUserName.length + 2;
      textarea.focus();
      textarea.selectionStart = textarea.selectionEnd = newCursorPosition;
    }, 0);
  }

  onProgressSelect(event: MatSelectChange) {
    const selectedProgressObject = this.progressList.find(progress => progress.stS_ID === event.value);
    this.params.controls['progress'].setValue(selectedProgressObject);
  }

  trackByProgressFn(index, item) {
    return item.stS_ID;
  }

  compareProgress(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.stS_ID === obj2.stS_ID : obj1 === obj2;
  }

  compareStatus(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.stsC_ID === obj2.stsC_ID : obj1 === obj2;
  }

  getCatalogs() {
    if (this.progressMode == 0) {
      this.puzzleService
        .getStatus(
          null,
          null,
          this.localService.getJsonValue("token").data.companyId,
          null
        )
        .subscribe((response) => {

          this.progressList = response;

          let selected = this.progressList.find(item => item.stS_ID == this.id);

          if (selected) {
            this.params.controls['progress'].setValue(selected);
          }
        });
    } else {
      this.puzzleService.getCandidateStatus().subscribe((response) => {
        this.statusList = response;

        let sselected = this.statusList.filter(
          (selected) => selected.stsC_ID == this.id
        );

        if (sselected != null && sselected.length > 0) {
          this.params.controls.status.setValue(sselected[0]);
        }
      });
      this.puzzleService.getReasonsForDiscard(100, 0, this.localService.getJsonValue("token").data.companyId).subscribe((response) => {
        this.reasonsList = response;
        const dropSource = this.reasonsList.find(reason => reason.droP_SOURCE_ID === this.candidate.droP_SOURCE_INFO?.droP_SOURCE_ID);
        this.params.controls['dropSource'].setValue(dropSource);
      });
    }
  }

  save() {
    const statusId = this.params.controls.status.value?.stsC_ID;
    const selectedProgress = this.params.controls.progress.value;

    if (this.progressMode == 1) {
      if (!statusId || statusId.trim() === "") {
        this.openResponseDialog(this.translate.instant("Error"), this.translate.instant("changeStatusAlert"));
        return;
      }
    } else {
      if (!selectedProgress || !selectedProgress.stS_ID) {
        this.openResponseDialog(this.translate.instant("Error"), this.translate.instant("changeStatusPhaseAlert"));
        return;
      }
    }

    if (this.params.controls.name.value == null || this.params.controls.name.value.trim() == "") {
      this.openResponseDialog(this.translate.instant("Error"), this.translate.instant("changeStatusMessageAlert"));
      return;
    }

    let request = new UpdateCandidateStaPro();
    request.cdT_ID = this.candidate.cdT_ID;
    if (!this.sending) {
      this.sending = true;
      if (this.progressMode == 1) {
        const status = this.params.controls.status.value;
        request.cdT_STATUS_CANDIDATE_ID = status.stsC_ID;
        request.ccOM_COMMENT = status.stsC_NAME + " - " + this.params.controls.name.value;
        request.DROP_SOURCE_ID = this.params.controls.dropSource.value?.droP_SOURCE_ID;
        request.CDT_ENTRY_DATE = this.hiredVacancyDate;
        if (status.stsC_ID == 'e7630126-4719-4e8d-8ab7-8146d06bc6ff') {
          request.CDT_NOTIFICATION_NOW = this.sendInstantMail;
        }
        this.puzzleService
          .updateCandidateStatus(request)
          .subscribe((response) => {
            if (response.isSuccess) {
              this.openResponseDialog("Exito", this.translate.instant(response.message));
              this.dialogRef.close(1);
            } else {
              if (response.messageCode == 'VACANCY_FULL_ERROR') {
                this.openResponseDialog("Error", this.translate.instant('vacancyFullError'));
              } else {
                this.openResponseDialog(this.translate.instant("changeStatusStatusError"), this.translate.instant(response.message));
              }
            }
            this.sending = false;
          });
      } else {
        request.ccOM_COMMENT = selectedProgress.stS_NAME + " - " + this.params.controls.name.value;
        request.cdT_STATUS_ID = selectedProgress.stS_ID;

        this.puzzleService
          .updateCandidateProgress(request)
          .subscribe((response) => {
            if (response.isSuccess) {
              this.openResponseDialog("Exito", this.translate.instant(response.message));
              this.dialogRef.close(1);
            } else {
              this.openResponseDialog(this.translate.instant("changeStatusProgressError"), this.translate.instant(response.message));
            }
            this.sending = false;
          });
      }
    }
  }

  openResponseDialog(response: string, message?: string) {
    let data: string[] = [];
    if (response == "Error") {
      data.push("Error");
      data.push(message);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: "500px",
        /* height: '400px', */
        data: data,
      });
    } else if (response == "Exito") {
      data.push("Exito");
      data.push(message);

      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: "500px",
        /* height: '400px', */
        data: data,
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  getTranslatedStatus(status: string): string {
    const statusKey = status.replace(/\s+/g, '').toLowerCase();
    return this.translate.instant(statusKey);
  }

  handleKeyDownTab(event: KeyboardEvent) {
    // Permitir barra espaciadora
    if (event.key === ' ') {
      return;
    }
    // Permitir el tabulador
    if (event.key !== 'Tab') {
      // Prevenir la entrada de texto
      event.preventDefault();
    }
  }
}
