import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PuzzleService } from '../services/puzzle.service';
import { MatDialog } from '@angular/material/dialog';
import { ResponseDialogComponent } from '../dialogs/response-dialog/response-dialog.component';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  resetForm: FormGroup;
  token: string;
  email: string;
  currentLang: string;
  recoverTitlePassword: string;
  recoverEmailPassword: string;
  recoverButtonPassword: string;
  recoverBackLoginPassword: string;
  passwordMismatch: boolean = false;
  hide: boolean = true;
  hide2: boolean = true;
  enableButton: boolean = false;
  haibuIcon: SafeResourceUrl = '../../assets/icons/haibu-icon.svg';


  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private translate: TranslateService,
    private puzzleService: PuzzleService,
    public dialog: MatDialog,
    private router: Router
  ) { }


  ngOnInit(): void {
    this.initializeLanguage();
    this.currentLang = this.translate.currentLang;
    this.resetForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]]
    });

    this.translate.get('resetPasswordTitle').subscribe((res: string) => {
      this.recoverTitlePassword = res;
    });

    this.translate.get('resetPasswordNewPassword').subscribe((res: string) => {
      this.recoverEmailPassword = res;
    });

    this.translate.get('resetPasswordNewPassword2').subscribe((res: string) => {
      this.recoverButtonPassword = res;
    });

    this.translate.get('resetPasswordConfirm').subscribe((res: string) => {
      this.recoverBackLoginPassword = res;
    });

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
    });

    this.updateTexts();


  }

  initializeLanguage() {
    const browserLang = this.translate.getBrowserLang();
    let appLang = this.mapBrowserLangToAppLang(browserLang);
    this.translate.use(appLang);
    this.currentLang = appLang;
  }

  changeLanguage(lang: string): void {
    const newLang = lang === 'es' ? 'esp' : 'eng';
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.updateTexts();
  }

  updateTexts(): void {
    this.translate.get('resetPasswordTitle').subscribe((res: string) => {
      this.recoverTitlePassword = res;
    });

    this.translate.get('resetPassword').subscribe((res: string) => {
      this.recoverEmailPassword = res;
    });

    this.translate.get('resetPassword2').subscribe((res: string) => {
      this.recoverButtonPassword = res;
    });

    this.translate.get('resetPasswordConfirm').subscribe((res: string) => {
      this.recoverBackLoginPassword = res;
    });
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'en': 'eng',
      'es': 'esp'
    };
    return langMap[browserLang] || 'eng';
  }

  onSubmit() {
    this.passwordMismatch = false;
    this.resetForm.markAllAsTouched();
    if (this.resetForm.valid) {
      const newPassword = this.resetForm.get('newPassword').value;
      const confirmPassword = this.resetForm.get('confirmPassword').value;

      if (newPassword !== confirmPassword) {
        this.passwordMismatch = true;
        return;
      }

      const passwordResetData = {
        Email: this.email,
        NewPassword: newPassword,
        Token: this.token,
      };

      this.puzzleService.resetPassword(passwordResetData).subscribe(response => {
        if (response.isSuccess) {
          const successMessage = this.translate.instant('userEditPasswordSuccess');
          this.openResponseDialog('Exito', successMessage);
        } else {
          const errorMessage = this.translate.instant('userEditPasswordError');
          this.openResponseDialog('Error', errorMessage);
        }
      }, error => {
        if (error.error?.message === 'Password reset failed: Invalid token.') {
          const errorMessage = this.translate.instant('userEditPasswordInvalidToken');
          this.openResponseDialog('Error', errorMessage);
          return;
        } else if (error.error?.message === 'Your account is registered via Google, LinkedIn, or Facebook.') {
          const errorMessage = this.translate.instant('userEditPasswordSocialMedia');
          this.openResponseDialog('Error', errorMessage);
          return;
        }
        const errorMessage = this.translate.instant('userEditPasswordError');
        this.openResponseDialog('Error', errorMessage);
      });
    }
  }

  openResponseDialog(response: string, message?: string) {
    let data: string[] = [];
    data.push(response);
    data.push(message);

    const dialogRef = this.dialog.open(ResponseDialogComponent, { width: '500px', data: data });

    dialogRef.afterClosed().subscribe(result => {
      if (response === 'Exito') {
        this.router.navigate(['/login']); // Asegúrate de que la ruta '/login' es correcta
      }
      this.enableButton = false;
    });  }
}
