<div class="px-1">
  <div class="text-2xl" style="color: #718096; border-bottom: 1px solid #ebebeb">
    {{'newEvaluationTitle' | translate}}
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="primerPaso">
    <div class="py-3 break-all" style="color: #728096">
      {{'newEvaluationSelectClient' | translate}}
    </div>
    <div class="my-2">
      <label for="cliente" class="gray font-medium"> {{'newEvaluationSelectBasset' | translate}} </label>
      <mat-select id="newEvaluationSelectBassetBtn" [(ngModel)]="selectedClient" name="cliente"
        class="borde my-1 w-full" style="padding-top: 8px; padding-bottom: 8px; color: #728096"
        [disabled]="availableClients()" required>
        <mat-option *ngFor="let client of clients" [value]="client.bssC_BASSET_CLIENT_ID"
          (click)="getSelectedClient(client); veSegundoPaso = false">
          {{ client.bssC_BASSET_CLIENT_NAME }}
        </mat-option>
      </mat-select>
    </div>
    <div class="my-2">
      <label for="socialrazon" class="gray font-medium"> {{'newEvaluationSocialReason' | translate}} </label>
      <mat-select id="newEvaluationSocialReasonBtn" [(ngModel)]="selectedRazon" name="socialrazon" id=""
        class="borde my-1 w-full" style="padding-top: 8px; padding-bottom: 8px; color: #728096"
        [disabled]="availableSocialReasons()" required>
        <mat-option *ngFor="let razonSocial of razonesSociales; let i = index" [value]="razonSocial.cliente_id"
          (click)="veSegundoPaso = true">
          {{ razonSocial.cliente_rs_name }}
        </mat-option>
      </mat-select>
    </div>
    <div *ngIf="muestraMensajeRazones" class="text-center" style="color: #fa284f">
      {{'newEvaluationSocialReasonValidation' | translate}}
    </div>
    <div class="flex flex-row mt-8">
      <button id="candidateDetailCancellButton" class="px-3 w-1/3" mat-button
        style="background-color: #fa284f; color: white; line-height: 30px" (click)="cerrar()">
        {{'candidateDetailCancellButton' | translate}}
      </button>
      <button id="newEvaluationNext" class="w-1/3" mat-button (click)="primerPaso = false; segundoPaso = true"
        [disabled]="!veSegundoPaso" style="
          background-color: #28b7fa;
          color: white;
          line-height: 30px;
          margin-left: auto;
        ">
        <span>{{'newEvaluationNext' | translate}}</span>
      </button>
    </div>
  </div>
  <div *ngIf="segundoPaso">
    <div class="py-3 break-all" style="color: #728096">
      {{'newEvaluationSelect' | translate}}
    </div>
    <div class="my-2 flex flex-row">
      <mat-select [(ngModel)]="selectedEvaluation" id="newEvaluationSelect" class="borde my-1 w-full"
        style="padding-top: 8px; padding-bottom: 8px; color: #728096" required>
        <!-- <mat-option value = "0">
            Selecciona una evaluación
        </mat-option> -->
        <mat-option *ngFor="let evaluation of evaluations; let i = index" [value]="evaluation.id">
          {{ evaluation.name }}
        </mat-option>
      </mat-select>
      <button id="addEvaluationBtn" mat-mini-fab color=""
        class="mat-elevation-z0 flex justify-center items-center self-center"
        style="background-color: #00b92d; margin-left: 1rem" (click)="addEvaluation()">
        <mat-icon class="pb-1" style="
            color: white;
            background-color: #00b92d;
            width: unset;
            height: unset;
          ">add</mat-icon>
      </button>
    </div>
    <div *ngFor="let reqEval of requestedEvaluations; let i = index" class="flex py-2 justify-between w-full">
      <button id="reqEvalBtn" class="w-full font-medium" style="padding: 10px; background-color: gray; color: white">
        {{ reqEval.name }}
      </button>

      <button id="deleteSelectedEvaluationBtn" (click)="deleteSelectedEvaluation(reqEval)" class="flex items-center"
        style="background-color: #fa284f">
        <mat-icon style="
            background-color: #fa284f;
            padding: 10px;
            color: white;
            width: unset;
            height: unset;
          ">delete</mat-icon>
      </button>
    </div>
    <div class="flex flex-row mt-8">
      <button id="newEvaluationBackBtn" class="px-3 w-1/3" mat-button
        style="background-color: #fa284f; color: white; line-height: 30px"
        (click)="segundoPaso = false; primerPaso = true">
        {{'newEvaluationBack' | translate}}
      </button>
      <button id="newEvaluationNextBtn" class="w-1/3" mat-button [disabled]="availableEvaluations()"
        (click)="checaTercerPaso()" style="
          background-color: #28b7fa;
          color: white;
          line-height: 30px;
          margin-left: auto;
        ">
        <span> {{'newEvaluationNext' | translate}} </span>
      </button>
    </div>
  </div>
  <div *ngIf="confirmaEvaluaciones">
    <div class="text-center py-3 break-all" style="color: #728096">
      {{'newEvaluationConfirm' | translate}}
    </div>
    <div class="blueTitle text-center" *ngFor="let evaluation of requestedEvaluations">
      {{ evaluation.name }}
    </div>
    <div class="mt-4 text-center" style="color: #fc424a">
      {{'newEvaluationNote' | translate}}
    </div>
    <div class="flex flex-row mt-8">
      <button id="closeBtnNewEval" class="px-3 w-1/3" mat-button
        style="background-color: #fa284f; color: white; line-height: 30px" (click)="cerrar()">
        {{'newEvaluationCancelButton' | translate}}
      </button>
      <button id="requestEvaluationsBtn" class="w-1/3" mat-button style="
          background-color: #28b7fa;
          color: white;
          line-height: 30px;
          margin-left: auto;
        " (click)="requestEvaluations()">
        <span> {{'newEvaluationConfirmButton' | translate}} </span>
      </button>
    </div>
  </div>

  <div *ngIf="estudioSocioeconomico">
    <div class="py-3 text-l" style="color: #728096">
      {{'newEvaluationIndication' | translate}}
    </div>
    <div class="flex flex-col items-center md:px-16 px-12">
      <mat-checkbox id="newEvaluationInclude" [(ngModel)]="extraEstudiosSocioeconomicos.investigacionlaboral"
        class="w-full p-2 grayText">{{'newEvaluationInclude' | translate}}</mat-checkbox>
      <mat-checkbox id="newEvaluationUrgent" [(ngModel)]="extraEstudiosSocioeconomicos.urgente"
        class="w-full p-2 grayText">{{'newEvaluationUrgent' | translate}}</mat-checkbox>
    </div>
    <div class="flex flex-row mt-8">
      <button id="newEvaluationBackBtn2" class="px-3 w-1/3" mat-button
        style="background-color: #fa284f; color: white; line-height: 30px"
        (click)="estudioSocioeconomico = false; segundoPaso = true">
        {{'newEvaluationBack' | translate}}
      </button>
      <button id="checkPruebasPsicometricas" class="w-1/3" mat-button style="
          background-color: #28b7fa;
          color: white;
          line-height: 30px;
          margin-left: auto;
        " (click)="checkPruebasPsicometricas()">
        <span> {{'newEvaluationNext' | translate}} </span>
      </button>
    </div>
  </div>

  <div *ngIf="pruebasPsicometricas">
    <div class="py-3 break-all text-center" style="color: #728096">
      {{'newEvaluationTestRequired' | translate}}
    </div>
    <!-- <div class="flex flex-col w-full">
    <div class="flex flex-row w-full">
      <mat-checkbox [disabled]="availablePruebasPsicometricas.evaluador" [(ngModel)]="availablePruebasPsicometricas.ciesman"  class="w-1/2 grayText text-lg">CIESMAN</mat-checkbox>
      <mat-checkbox [disabled]="availablePruebasPsicometricas.evaluador" [(ngModel)]="availablePruebasPsicometricas.cacter"  class="w-1/2 grayText text-lg">COEFIV</mat-checkbox>
    </div>
    <div class="flex flex-row w-full">
      <mat-checkbox [disabled]="availablePruebasPsicometricas.evaluador" [(ngModel)]="availablePruebasPsicometricas.cibain"  class="w-1/2 grayText text-lg">CACTER</mat-checkbox>
      <mat-checkbox [disabled]="availablePruebasPsicometricas.evaluador" [(ngModel)]="availablePruebasPsicometricas.competian"  class="w-1/2 grayText text-lg">GENLINDER</mat-checkbox>
    </div>
    <div class="flex flex-row w-full">
      <mat-checkbox [disabled]="availablePruebasPsicometricas.evaluador" [(ngModel)]="availablePruebasPsicometricas.coefiv"  class="w-1/2 grayText text-lg">CIBAIN</mat-checkbox>
      <mat-checkbox [disabled]="availablePruebasPsicometricas.evaluador" [(ngModel)]="availablePruebasPsicometricas.genlinder"  class="w-1/2 grayText text-lg">INCEVAL</mat-checkbox>
    </div>
    <div class="flex flex-row w-full">
      <mat-checkbox [disabled]="availablePruebasPsicometricas.evaluador" [(ngModel)]="availablePruebasPsicometricas.inceval"  class="w-1/2 grayText text-lg">COMPETIAN</mat-checkbox>
      <mat-checkbox [disabled]="availablePruebasPsicometricas.evaluador" [(ngModel)]="availablePruebasPsicometricas.coleibor"  class="w-1/2 grayText text-lg">COLEIBOR</mat-checkbox>
    </div>
    <div class="flex w-full justify-start mt-6">
      <mat-checkbox (change)="onCheckEvaluador($event)" [(ngModel)]="availablePruebasPsicometricas.evaluador" class="w-full grayText text-lg">Aplicar las pruebas psicométricas que el evaluador de Basset considere pertinentes</mat-checkbox>
    </div>
  </div> -->
    <div class="flex flex-col w-full">
      <div class="flex flex-wrap w-full">
        <mat-checkbox id="availablePruebasPsicometricas"
          *ngFor="let prueba of availablePruebasPsicometricas; let i = index" matTooltip="{{prueba.description}}"
          [(ngModel)]="availablePruebasPsicometricas[i].selected" [disabled]="evaluador"
          class="flex md:px-16 px-4 my-1 w-1/2 grayText hover:bg-gray-200 rounded">
          {{ prueba.evp_psicometrica_prueba_name }}
        </mat-checkbox>
      </div>
      <div class="flex w-full justify-start mt-6">
        <mat-checkbox id="newEvaluationMakeTest" (change)="onCheckEvaluador($event)" [(ngModel)]="evaluador"
          class="w-full text-justify grayText md:px-16 px-4 hover:bg-gray-200">{{'newEvaluationMakeTest' |
          translate}}</mat-checkbox>
      </div>
    </div>

    <div class="flex flex-row mt-8">
      <button id="regresaPruebasBtn" class="px-3 w-1/3" mat-button
        style="background-color: #fa284f; color: white; line-height: 30px" (click)="regresaPruebas()">
        {{'newEvaluationBack' | translate}}
      </button>
      <button id="canYouFinishRequestBtn" class="w-1/3" mat-button [disabled]="canYouFinishRequest()" style="
          background-color: #28b7fa;
          color: white;
          line-height: 30px;
          margin-left: auto;
        " (click)="confirmaEvaluaciones = true; pruebasPsicometricas = false">
        <span> {{'newEvaluationNext' | translate}} </span>
      </button>
    </div>
  </div>

  <div class="my-4 flex flex-col justify-center" *ngIf="loading">
    <div class="text-2xl blueTitle text-center">
      {{'newEvaluationLoading' | translate}}
    </div>
    <div class="flex justify-center">
      <img src="/assets/animations/haibu-loading.gif" style="height: 120px; width: 120px" />
    </div>
  </div>
</div>