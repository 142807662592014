import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
  exportAs: 'appClickOutside'
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<MouseEvent>();
  @Input() excludeDatepicker: boolean = false; // Nueva propiedad opcional
  private isDatepickerOpen: boolean = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && (!this.excludeDatepicker || !this.isDatepickerOpen)) {
      this.clickOutside.emit(event);
    }
  }

  setDatepickerOpen(isOpen: boolean) {
    this.isDatepickerOpen = isOpen;
  }
}
