import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { APIResponse } from 'src/app/interfaces/response';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-duplicate-candidate',
  templateUrl: './duplicate-candidate.component.html',
  styleUrls: ['./duplicate-candidate.component.css']
})
export class DuplicateCandidateComponent implements OnInit {

  sending: boolean = false;
  candidate: any;
  companies: any[] = [];
  selectedCompany: any;
  vacancies: any[] = [];
  selectedVacancy: any;
  canCreate: boolean = true;
  companyRequiredError: string;
  vacancyRequiredError: string;
  companyEmptyError: string;
  token: APIResponse = this.localService.getJsonValue('token');

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private dialogRef: MatDialogRef<DuplicateCandidateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.candidate = data;
  }

  ngOnInit(): void {
    this.getCompaniesList()
  }

  getCompaniesList() {
    this.puzzleService.getUserCompanies().subscribe(res => {
      this.companies = res.map((company: any) => {
        return {
          value: company.comP_ID,
          label: company.comP_NAME
        }
      });
      this.selectedCompany = this.companies[0].value;
      this.getVacancies({ value: this.selectedCompany });
    });
  }

  getVacancies(event: any) {
    this.selectedCompany = event.value;
    this.vacancies = [];
    this.canCreate = true;
    this.companyRequiredError = '';
    this.vacancyRequiredError = '';
    this.companyEmptyError = '';

    const user = {
      UserId: this.token.data.id,
      CompanyId: this.selectedCompany
    }

    this.puzzleService.getTokenForCompany(user).subscribe(res => {
      if (res.isSuccess) {
        const relevantPermissions = ['v005', 'v006'];
        const userPermissions = res.data.permissions
          .map(permission => permission.code)
          .filter(code => relevantPermissions.includes(code));

        let permissionCode = '';
        if (userPermissions.includes('v006')) {
          permissionCode = 'v006'; // ver solo donde participa
        } else if (userPermissions.includes('v005')) {
          permissionCode = 'v005'; // ver todos
        }

        // checar si tiene permiso cd004
        this.canCreate = res.data.permissions.filter(permission => permission.code === 'cd004').length > 0;

        if (this.canCreate) {
          this.puzzleService.getVacancies(this.selectedCompany, true, true, 1, 1000, undefined, undefined, undefined, undefined, undefined, undefined, undefined, permissionCode).subscribe(res => {
            this.vacancies = res.map((vacancy: any) => {
              return {
                value: vacancy.vacanT_ID,
                label: vacancy.jobInfo.joB_NAME + ' - ' + vacancy.departmentInfo.deP_NAME + ' - ' + vacancy.branchOfficeInfo.broF_NAME
              }
            });
            if (this.vacancies.length === 0) {
              this.companyEmptyError = this.translate.instant('companyEmptyError');
            }
          });
        }
      }
    });
  }

  duplicateCandidate() {
    if (this.sending) {
      return;
    }
    this.sending = true;
    if (!this.selectedCompany) {
      this.companyRequiredError = this.translate.instant('companyRequiredError');
      this.sending = false;
      return;
    }
    if (!this.selectedVacancy) {
      this.vacancyRequiredError = this.translate.instant('vacancyRequiredError');
      this.sending = false;
      return;
    }
    if (this.companyEmptyError) {
      this.sending = false;
      return;
    }

    const data = {
      OriginalCandidateId: this.candidate.cdT_ID,
      CompanyId: this.selectedCompany,
      VacantId: this.selectedVacancy
    }

    this.puzzleService.duplicateCandidate(data).subscribe((response) => {
      if (response.isSuccess) {
        this.dialogRef.close({
          isSuccess: true,
          message: response.message
        });
      } else {
        this.dialogRef.close({
          isSuccess: false,
          message: response.message
        });
      }
    }, (error) => {
      this.dialogRef.close(
        {
          isSuccess: false,
          message: this.translate.instant('candidateDuplicatedError'),
        }
      );
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  openResponseDialog(response: string, message?: string, extra?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      if (extra) data.push(extra);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);
      if (extra) data.push(extra);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }
}
