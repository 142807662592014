import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PuzzleService } from '../../services/puzzle.service';
import { LocalService } from '../../services/local.service';
import { environment } from '../../../environments/environment.prod';
import { isRegExp } from 'util';
import { ResponseSmallDialogComponent } from '../../dialogs/response-small-dialog/response-small-dialog.component';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(private router: Router, private puzzleService: PuzzleService, public dialog: MatDialog, private localService: LocalService) { }

  url:string = environment.apiUrlUnsafe;

  @ViewChild('download') divClick: ElementRef;

  ngOnInit(): void {
    // this.openResultDialog(0, 'Todo mal mano');

    let processid: string = '';

    // this.openResultDialog(1, 'Todo bien mano');

    const queryString = window.location.search;
 
    const urlParams = new URLSearchParams(queryString);

    processid = urlParams.get('processid');

    if(processid){
      
      this.puzzleService.validateLink(processid).subscribe(
        resp => {
          if (resp.isSuccess) {
            this.openResultDialog(1, 'Éxito en tu petición de descarga.')
            this.url += `/api/CandidateExternal/DownloadCandidateExcel?processId=${processid}`;
            setTimeout(() => {
              this.divClick.nativeElement.click();
            }, 200);
            
          }
          else this.openResultDialog(0, resp.message)
        }
        )
      } else {
        this.openResultDialog(0, 'Ocurrió un error interno')
      }

    
    // this.puzzleService.downloadExcel(processid).subscribe(
    //   response => {
    //     console.log(response);
    //   }
    // )

  }

  openResultDialog(mode: number, message?: string) {
    let data: string[] = [];
    switch(mode){
      case 0:{
        data.push('Error');
        data.push(message);
        return this.dialog.open(ResponseSmallDialogComponent, {
          width: '500px',
          data: data
        });
      }break;
      case 1:{
        data.push('Exito');
        data.push(message);
  
        return this.dialog.open(ResponseSmallDialogComponent, {
          width: '500px',
          data: data
        });
      }break;
      case 2:{
        data.push('loading');
        data.push(message);

    
        return this.dialog.open(ResponseSmallDialogComponent, {
          width: '500px',
          data: data
        });
      }break;

    }
     
  }

}
