import { Component, OnInit } from '@angular/core';
import { PuzzleService } from '../services/puzzle.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {

  terms: any;
  spin:boolean = false;
  currentLang: string;
  haibuIcon: SafeResourceUrl = '../../assets/icons/haibu-icon.svg';

  constructor(private puzzleService: PuzzleService, private translate: TranslateService) { }


  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.loadTerms();
/*     console.log(this.terms);
  // console.log(this.currentLang); */
  }

  loadTerms() {
    const appLang = this.translate.currentLang === 'esp' ? 'es_privacy' : 'en_privacy';
    this.puzzleService.getTerms(appLang).subscribe(data => {
      this.terms = data;
    });
/*     console.log(this.terms);
 */  }

  changeLanguage(lang: string) {
    const newLang = lang === 'es' ? 'esp' : 'eng';
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.loadTerms();
  }


}
