import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PassChange } from 'src/app/interfaces/PassChange';
import { APIResponse } from 'src/app/interfaces/response';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidatorService } from '../../services/validator.service';

@Component({
  selector: 'app-user-password-dialog',
  templateUrl: './user-password-dialog.component.html',
  styleUrls: ['./user-password-dialog.component.css']
})
export class UserPasswordDialogComponent implements OnInit {

  constructor(
    private validatorService: ValidatorService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<UserPasswordDialogComponent>,
    public dialog: MatDialog, private localService: LocalService,
    private puzzleService: PuzzleService,
    private fb: FormBuilder
  ) { }

  passChange: PassChange = {} as PassChange;
  userLocal: APIResponse = this.localService.getJsonValue('token');
  hide: boolean = true;
  hide2: boolean = true;
  hide3: boolean = true;

  sending: boolean = false;

  myForm: FormGroup = this.fb.group(
    {
      password: ['', [Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(6)]],
      new_password2: ['', [Validators.required]],
    },
    {
      validators: [
        this.validatorService.camposIguales('new_password', 'new_password2'),
      ],
    }
  )

  ngOnInit(): void {
    this.passChange.id = this.userLocal.data.id;
  }

  sendPassChange() {
    if (this.sending) {
      return
    }

    this.myForm.markAllAsTouched()

    if (!this.myForm.invalid) {
      this.sending = true;
      this.passChange.password = this.myForm.get('password')?.value;
      this.passChange.anU_NEW_PASSWORD = this.myForm.get('new_password')?.value;
      this.passChange.anU_CONFIRM_NEW_PASSWORD = this.myForm.get('new_password2')?.value;
      this.puzzleService.updatePass(this.passChange).subscribe(response => {
        if (response.isSuccess) {
          this.dialogRef.close();
          const message = this.translate.instant(response.message);
          this.openResultDialog(1, message)
        } else {
          const message = this.translate.instant(response.message);
          this.openResultDialog(0, message)
          this.sending = false;
        }
      }, error => {
        this.sending = false;
        const translatedMessage = this.translate.instant("userPasswordDialogAlertError");
        this.openResultDialog(0, translatedMessage);
      });
    }


  }


  openResultDialog(mode: number, message?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0: {
        data.push('Error');
        data.push(message);
        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          data: data
        });
      } break;
      case 1: {
        data.push('Exito');
        data.push(message);

        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          data: data
        });
      } break;
      case 2: {
        data.push('loading');
        data.push(message);


        return this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          data: data
        });
      } break;

    }

  }

  closeModal() {
    this.dialogRef.close();
  }

}
