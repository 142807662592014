// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://haibuhr.com/develop',
  imageUrl: 'https://webapi.haibuhr.com/',
  candidateUrl: 'https://haibuhr.com/',
  candidateUrlExternal: 'https://haibuhr.com/',
  apiUrlUnsafe: 'https://webapi.haibuhr.com',
  apiDevelBassetUrl: "https://mi.basset.com.mx/",
/*    apiUrl: 'https://devel.haibuhr.com/develop',
  imageUrl: 'https://webapi-devel.haibuhr.com/',
  candidateUrl: 'https://devel.haibuhr.com/',
  candidateUrlExternal: 'https://devel.haibuhr.com/',
  apiUrlUnsafe: 'https://webapi-devel.haibuhr.com',
  apiDevelBassetUrl: "https://devel.portal.basset.com.mx", */
  recaptcha: {
    siteKey: '6LdmHjwdAAAAAJMVmAWyzAyWr5d5Jq9B11jNJ4qZ',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
