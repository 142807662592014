<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
  <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
    <mat-icon class="close-x-primary">close</mat-icon>
  </button>
</div>

<div class="pb-3 text-center title-text-modal">
  {{ esNuevo ? ('addJobEditName' | translate) : ('addJobCreateName' | translate) }}
</div>

<div class="md:px-6 px-0">
  <form [formGroup]="miFormulario">
    <div class="pt-6 pb-3">
      <div idTour="addJob.name" class="flex flex-col">
        <label class="pr-6 green-subtitle" for="nombre">{{ 'addJobName' | translate
          }}</label>
        <div class="flex">
          <input id="addJobName" formControlName="nombre" class="p-2 w-full mt-1 borde" type="text" name="nombre"
            (keydown.enter)="addJob($event)" maxlength="100" />
          <div *ngIf="!esNuevo" class="ml-2 mt-2">
            <a id="addJobNameBtn" mat-mini-fab
              class="mat-elevation-z0 flex justify-center items-center primary-background" (click)="addJob()"
              type="button">
              <mat-icon class="pb-1">add</mat-icon>
            </a>
          </div>
        </div>
        <div
          *ngIf="miFormulario.get('nombre').invalid && (miFormulario.get('nombre').dirty || miFormulario.get('nombre').touched)"
          class="text-red-500 text-xs mt-1">
          <span *ngIf="miFormulario.get('nombre').errors['required']">
            {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('addJobName' | translate) } }}
          </span>
          <span *ngIf="miFormulario.get('nombre').errors['noSpecialCharacters']">
            {{ 'invalidCharactersKeyFormCatalogs' | translate }}
          </span>
        </div>
      </div>
    </div>

    <div class="pb-3">
      <div idTour="addJob.description">
        <label class="pr-6 pt-2 green-subtitle" for="descripcion">{{ 'addJobDescriptionMicro' |
          translate }}</label>
        <div class="w-full mt-1 mb-2 quill">
          <quill-editor id="addJobDescription" formControlName="descripcion" [modules]="editorOptions"
            [styles]="{borderRadius: '0px 0px 8px 8px', height: '30vh'}" placeholder="" format="html" [sanitize]="true"
            maxlength="10000" (onContentChanged)="onEditorContentChange($event)"></quill-editor>
        </div>
  
        <div
          *ngIf="miFormulario.get('descripcion').invalid && (miFormulario.get('descripcion').dirty || miFormulario.get('descripcion').touched)"
          class="text-red-500 text-xs mt-1">
          <span *ngIf="miFormulario.get('descripcion').errors['required']">
            {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('addJobDescription' | translate) } }}
          </span>
          <span *ngIf="!miFormulario.get('descripcion').errors['required']">
            {{ 'jobDescriptionLengthError' | translate }}
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="!esNuevo" class="flex overflow-x-auto max-w-full">
      <ul class="flex space-x-4 py-2">
        <li *ngFor="let puesto of listaPuestos; let i = index"
          class="bg-gray-200 rounded-full px-4 py-1 flex items-center space-x-2">
          <span>{{ puesto.joB_NAME }}</span>
          <button id="deleteJobDialog" (click)="deleteJob(i)" class="text-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </li>
      </ul>
    </div>

    <div class="flex flex-col md:flex-row md:justify-center md:items-center"
      *ngIf="esNuevo && !selectedFile && !job.jdoC_ACTIVE && !job.jdoC_DOCUMENT_ROUTE">
      <div class="flex flex-col md:flex-row md:justify-center md:items-center w-full">
        <input #fileSelect id="fileSelect" type="file"
          accept=".doc,.docx,application/msword,application/vnd.ms-excel,.xlsx,.xls,image/*,application/pdf,.pdf"
          (click)="fileSelect.value = null" (change)="fileChangeEvent($event,0)" class="hidden">
        <app-custom-primary-button id="jobCatalogeAddProfile" class="flex-1 md:mr-2 mb-2 md:mb-0" size="button-profile"
          color="secondary" (click)="fileSelectClick()" [disabled]="sending">
          {{ 'jobCatalogeAddProfile' | translate }}
        </app-custom-primary-button>
        <app-custom-primary-button id="jobCatalogeDesignProfile" class="flex-1" size="button-profile" color="secondary"
          (click)="openDocument('assets/logos/perfil_de_puesto.pdf', true)" [disabled]="sending">
          {{ 'jobCatalogeDesignProfile' | translate }}
        </app-custom-primary-button>
      </div>
    </div>

    <div *ngIf="selectedFile">
      <a id="catalogueStatusProfileName"
        class="document-card w-full gray-subtitle flex items-center justify-between p-2">
        <div class="flex items-center">
          <img [src]="fileIcon" alt="Document Icon" class="mr-2">
          {{ "catalogueStatusProfile2" | translate }}
        </div>
        <a id="deleteDocTempDialog" (click)="deletedoc()" class="flex items-center">
          <img [src]="deleteIcon" alt="trash">
        </a>
      </a>
    </div>

    <div *ngIf="job.jdoC_ACTIVE && job.jdoC_DOCUMENT_ROUTE">
      <a id="catalogueStatusProfile" (click)="openDocument(job.jdoC_DOCUMENT_ROUTE, false)"
        class="document-card w-full gray-subtitle flex items-center justify-between p-2">
        <div class="flex items-center">
          <img [src]="fileIcon" alt="Document Icon" class="mr-2">
          {{ "catalogueStatusProfile2" | translate }}
        </div>
        <a id="deleteDocTempDialog2" (click)="deletedoc(job)" class="flex items-center">
          <img [src]="deleteIcon" alt="trash">
        </a>
      </a>
    </div>

    <div class="ml-auto md:w-full mt-6 flex flex-col md:flex-row md:justify-center md:items-center">
      <app-custom-primary-button idTour="addJob.save" id="addJobSaveDialog" type="submit" size="super-small-light"
        color="primary" class="text-center" (click)="submitForm()" [disabled]="sending">
        <span *ngIf="!sending">{{ 'addJobSave' | translate }}</span>
        <div *ngIf="sending" class="w-full flex justify-center">
          <mat-spinner diameter="20" color="primary"></mat-spinner>
        </div>
      </app-custom-primary-button>
    </div>
  </form>
</div>