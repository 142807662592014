import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeComponent } from '../../home/home.component';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
const BASE_VACANCY_URL = 'https://haibujobs.com';

@Component({
  selector: 'app-response-dialog',
  templateUrl: './response-dialog.component.html',
  styleUrls: ['./response-dialog.component.css']
})
export class ResponseDialogComponent implements OnInit {

  responseType: string;
  message: string;
  showNewButton: boolean;
  vacancyId?: string;



  constructor(
    private dialogRef: MatDialogRef<HomeComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any // Aceptamos data como `any`
  ) { }

  options: AnimationOptions = {
    path: '/assets/animations/checkmark.json',
  };

  options2: AnimationOptions = {
    path: '/assets/animations/error.json',
  };

  options3: AnimationOptions = {
    path: '/assets/animations/loading.json',
  };


  animationCreated(animationItem: AnimationItem): void {
  }

  ngOnInit(): void {
    if (Array.isArray(this.data)) {
      // Si `data` es un arreglo, asignamos valores desde los índices
      this.responseType = this.data[0] || '';
      this.message = this.data[1] || '';
      this.showNewButton = this.data[2] === 'true';
      this.vacancyId = this.data[3] || '';
    } else if (typeof this.data === 'object' && this.data !== null) {
      // Si `data` es un objeto, asignamos valores desde las propiedades
      this.responseType = this.data.responseType || '';
      this.message = this.data.message || '';
      this.showNewButton = this.data.showNewButton || false;
      this.vacancyId = this.data.vacancyId || '';
    }
  }

  cerrar() {
    this.dialogRef.close(true);
  }

  newButtonAction() {
    if (this.vacancyId) {
      const url = `https://haibujobs.com/${this.vacancyId}`;
      window.open(url, '_blank');
    } else {
      console.warn('No vacancy ID provided');
    }
  }
}
