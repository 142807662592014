import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PuzzleService } from '../../services/puzzle.service';
import { HomeComponent } from '../../home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentationTypeInfo } from 'src/app/interfaces/DocumentationTypeInfo';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-or-edit-document',
  templateUrl: './add-or-edit-document.component.html',
  styleUrls: ['./add-or-edit-document.component.css']
})

export class AddOrEditDocumentComponent implements OnInit {
  sendDocument: DocumentationTypeInfo = {} as DocumentationTypeInfo;
  listaDocumentos: DocumentationTypeInfo[] = [];
  esNuevo: boolean = false;
  tabCount: number = 0;
  modoActivar: boolean = false;
  document: DocumentationTypeInfo;
  sending: boolean = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.tabCount++;
      if (this.tabCount === 2) {
        const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
        if (submitButton) {
          submitButton.focus();
        }
      }
    } else if (event.key !== 'Enter') {
      this.tabCount = 0;
    }
  }

  @HostListener('document:keydown.tab', ['$event'])
  handleTabPress(event: KeyboardEvent) {
    this.tabCount++;
    if (this.tabCount === 2) {

      const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.focus();
      }
    }
  }
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterPress(event: KeyboardEvent) {
    if (this.esNuevo) return;

    if (this.tabCount === 1) {
      event.preventDefault();
      if (!this.esNuevo) {
        this.agregarDocumento();
      }
    } else if (this.tabCount >= 2) {
      event.preventDefault();
      this.submitForm();
    }
    this.tabCount = 0;
  }

  constructor(
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<HomeComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  miFormulario: FormGroup = this.fb.group({
    nombre: ['', [this.isRequiredIfEmptyArray.bind(this), this.noSpecialCharactersValidator]]
  });

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/()"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  public isRequiredIfEmptyArray(control: FormControl) {
    const value = control.value || '';

    // Supongo que listaDocumentos es una propiedad del componente que contiene el array.
    if (this.listaDocumentos.length === 0 && !value.trim()) {
      return { 'required': true };
    }

    return null;
  }

  ngOnInit(): void {
    if (this.data.accion === 'Activar') {
      this.modoActivar = true;
      this.document = this.data.data;
    } else if (typeof this.data.data === 'string') {
      this.esNuevo = false;
    } else if (this.data.data && typeof this.data.data === 'object') {
      this.esNuevo = true;
      this.document = this.data.data;
      this.miFormulario.patchValue({
        nombre: this.transformValue(this.document.pdT_NAME)
      });
    } else {
      console.error('Datos inválidos proporcionados al modal:', this.data);
      this.dialogRef.close();
    }
  }
  get modalTitle(): string {
    if (this.modoActivar) {
      return this.document.cdoC_LINK_ACTIVE ? this.translate.instant('addDocumentTitleDesctivate') : this.translate.instant('addDocumentTitleActivate');
    } else {
      return !this.esNuevo ? this.translate.instant('addDocumentCreateName') : this.translate.instant('addDocumentEditName');
    }
  }
  agregarDocumento(event?: Event) {
    if (!this.esNuevo) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.miFormulario.markAllAsTouched();
      const nombreValue = this.miFormulario.get('nombre').value || '';
      if (nombreValue.trim() !== '' && this.miFormulario.valid) {
        // validar que no exista el nombre en la lista
        const existeNombre = this.listaDocumentos.find(doc => doc.pdT_NAME === nombreValue);
        if (!existeNombre) {
          const documento: DocumentationTypeInfo = this.crearDocumento();
          this.listaDocumentos.unshift(documento);
          this.miFormulario.reset();
        } else {
          this.miFormulario.get('nombre')?.setValue('');
        }
      }
    }
  }

  async submitForm() {
    this.miFormulario.markAllAsTouched();

    const inputValue = this.miFormulario.get('nombre').value || '';

    if (this.miFormulario.valid && inputValue.trim() !== '') {
      const documento: DocumentationTypeInfo = {
        pdT_NAME: inputValue,
        pdT_COMPANY_ID: typeof this.data.data === 'string' ? this.data.data : this.data.data.pdT_COMPANY_ID,
        pdT_ID: typeof this.data.data === 'string' ? undefined : this.data.data.pdT_ID,
        pdT_ACTIVE: typeof this.data.data === 'string' ? false : this.data.data.pdT_ACTIVE,
        pdT_NUMBER: typeof this.data.data === 'string' ? undefined : this.data.data.pdT_NUMBER,
        pdT_VISIBLE: typeof this.data.data === 'string' ? false : this.data.data.pdT_VISIBLE,
        cdoC_LINK_ACTIVE: typeof this.data.data === 'string' ? false : this.data.data.cdoC_LINK_ACTIVE
      };
      this.listaDocumentos.unshift(documento);
      this.miFormulario.reset();
    }

    if (this.listaDocumentos.length > 0) {
      let ultimoNumeroFase = 0;
      let responses = [];
      this.sending = true;

      const promises = this.listaDocumentos.map(async (doc, index) => {
        doc.pdT_NUMBER = ultimoNumeroFase + 1 + index;

        try {
          const response = await this.puzzleService.createOrEditDocumentEntry(doc).toPromise();
          return {
            isSuccess: response.isSuccess,
            message: this.translate.instant(response.message),
            name: doc.pdT_NAME
          };
        } catch (error) {
          const errorMessage = this.translate.instant('addDepartamentAlert');
          return { isSuccess: false, message: errorMessage, name: doc.pdT_NAME };
        }
      });

      try {
        responses = await Promise.all(promises);
      } catch (error) {
        console.error("Error en las promesas:", error);
      }

      this.dialogRef.close(responses);
    } /* else {
      this.dialogRef.close([]);
    } */
  }

  enviarDocumento() {
    const documento: DocumentationTypeInfo = this.crearDocumento();
    this.puzzleService.createOrEditDocumentEntry(documento).toPromise().then(response => {
      const translatedMessage = this.translate.instant(response.message);
      const messageToShow = translatedMessage === response.message ? response.message : translatedMessage;

      this.dialogRef.close([{ isSuccess: response.isSuccess, message: messageToShow }]);
    });
  }

  crearDocumento(): DocumentationTypeInfo {
    return {
      pdT_NAME: this.miFormulario.get('nombre').value,
      pdT_COMPANY_ID: typeof this.data.data === 'string' ? this.data.data : this.data.data.pdT_COMPANY_ID,
      pdT_ID: typeof this.data.data === 'string' ? undefined : this.data.data.pdT_ID,
      pdT_ACTIVE: typeof this.data.data === 'string' ? false : this.data.data.pdT_ACTIVE,
      pdT_NUMBER: typeof this.data.data === 'string' ? undefined : this.data.data.pdT_NUMBER,
      pdT_VISIBLE: typeof this.data.data === 'string' ? false : this.data.data.pdT_VISIBLE,
      cdoC_LINK_ACTIVE: typeof this.data.data === 'string' ? false : this.data.data.cdoC_LINK_ACTIVE
    };
  }

  campoNoValido(campo: string): boolean {
    const campoValor = this.miFormulario.get(campo)?.value || '';
    if (this.listaDocumentos.length === 0) {
      return (this.miFormulario.get(campo)?.invalid || campoValor.trim() === '') && this.miFormulario.get(campo)?.touched;
    }
    return false;
  }

  eliminarDocumento(index: number): void {
    this.listaDocumentos.splice(index, 1);
  }


  toggleDocumentActiveState(activate: boolean): void {
    if (this.document && this.document.pdT_ID) {
      const updatedDocument: DocumentationTypeInfo = {
        ...this.document,
        cdoC_LINK_ACTIVE: activate
      };

      this.puzzleService.createOrEditDocumentEntry(updatedDocument).toPromise()
        .then(response => {
          const translationKey = activate ? 'addDocumentLinkUpdate' : 'addDocumentLinkDelete';
          const messageToShow = this.translate.instant(translationKey);
          this.dialogRef.close({ isSuccess: response.isSuccess, message: messageToShow });
        })
        .catch(error => {
          const errorMessage = this.translate.instant('updateError');
          console.error('Error al actualizar el documento', error);
          this.dialogRef.close({ isSuccess: false, message: errorMessage });
        });
    } else {
      console.error('Error: Documento no disponible o ID no definido.');
      this.dialogRef.close({ isSuccess: false, message: 'Documento no válido.' });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]/g, '').trimStart();
  }

}
