<div #tourOverlay *ngIf="isOverlayVisible" id="tourOverlay">
    <!-- Cuatro secciones para el overlay alrededor del elemento resaltado -->
    <div class="overlay">
        <div class="overlay-top" [ngStyle]="getOverlaySectionStyles('top')"></div>
        <div class="overlay-left" [ngStyle]="getOverlaySectionStyles('left')"></div>
        <div class="overlay-right" [ngStyle]="getOverlaySectionStyles('right')"></div>
        <div class="overlay-bottom" [ngStyle]="getOverlaySectionStyles('bottom')"></div>
    </div>

    <!-- Cuadro del tour debajo del elemento resaltado -->
    <div class="tour-box" *ngIf="currentStep && highlightedElement" [ngStyle]="getHighlightStyles().tourBox">
        <p class="text-base font-medium text-gray-700">{{ currentStep.title }}</p>
        <p class="text-sm text-gray-500">{{ currentStep.content }}</p>

        <div class="tour-controls">
            <button (click)="previous()" *ngIf="!currentStep.disableBack" class="text-gray-700">{{ 'previous' | translate }}</button>
            <button *ngIf="currentStep.disableBack" class="text-gray-400">{{ 'previous' | translate }}</button>
            <button *ngIf="!currentStep.final" (click)="next()" class="text-gray-700">{{ 'next' | translate }}</button>
            <button *ngIf="currentStep.final" (click)="closeOverlay()" class="text-gray-700">{{ 'endTour' | translate }}</button>
        </div>
    </div>
</div>