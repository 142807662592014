<div class="w-full py-3 px-3 md:px-16">
  <div class="px-10 md:px-0 text-3xl pb-3 pt-3" style="color: #718096;">Mi equipo</div>
  <div style="background-color: white;" class="max-w-full">
    <div class="borde py-4">
      <div class="flex flex-col  md:flex-row  ">
        <div class="px-3   md:w-1/4">
          <label for="nombre" class="gray font-medium">Nombre de empleado:</label>
          <mat-form-field [formGroup]="params" appearance="outline" class="w-full">
            <input (keydown.enter)='getCandidates(true)' formControlName="name" matInput type="text" class="validate"
              placeholder="" name="nombre">
          </mat-form-field>
        </div>
        <!-- <div class = "px-3   md:w-1/4">
                    <label for="status" class = "gray font-medium" >Estatus:</label>
                    <mat-form-field  [formGroup] = "params" appearance="outline"  class="w-full">
                        <mat-select formControlName = "status" (selectionChange)='onStatusSelect($event.value)'  matNativeControl  >
                          <mat-option *ngFor="let status of statusList" [value]="status">{{status.stsC_NAME}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>

                <div class = "px-3     md:w-1/4">
                    <label for="progreso" class = "gray  font-medium"> Progreso: </label>
                    <mat-form-field [formGroup] = "params"  appearance="outline"  class="w-full">
                        <mat-select formControlName = "progress" (selectionChange)='onProgressSelect($event.value)'  matNativeControl  >
                          <mat-option *ngFor="let progress of progressList" [value]="progress">{{progress.stS_NAME}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div> -->

        <div class="px-3     md:w-1/4">
          <label for="department" class="gray font-medium">Departamento:</label>
          <mat-form-field [formGroup]="params" appearance="outline" class="w-full">
            <mat-select formControlName="department" (selectionChange)='onDepartmentSelect($event.value)'
              matNativeControl>
              <mat-option *ngFor="let department of departments"
                [value]="department">{{department.deP_NAME}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="px-3     md:w-1/4">
          <label for="progreso" class="gray  font-medium">Sucursal:</label>
          <mat-form-field [formGroup]="params" appearance="outline" class="w-full">
            <mat-select formControlName="branch" (selectionChange)='onBranchSelect($event.value)' matNativeControl>
              <mat-option *ngFor="let branch of branchs" [value]="branch">{{branch.broF_NAME}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="px-3     md:w-1/4">
          <label for="rango" class="gray font-medium">Fecha de contratación:</label>
          <mat-form-field class="w-full" appearance="outline">
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Fecha inicial">
              <input matEndDate formControlName="end" placeholder="Fecha final">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha de inicio inválida</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha final inválida</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="flex flex-col  md:flex-row pb-2">
        <!-- <div class = "px-3    md:w-1/4">
                    <label for="job" class = "gray font-medium" >Puesto:</label>
                    <mat-form-field [formGroup] = "params" appearance="outline" class="w-full">
                        <mat-select formControlName = "job" (selectionChange)='onJobSelect($event.value)'  matNativeControl  >
                            <mat-option *ngFor="let job of jobs" [value]="job">{{job.joB_NAME}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->



        <!-- <div class = "px-3     md:w-1/4">
                    <label for="reclutador" class = "gray font-medium" >Reclutador:</label>
                        <mat-form-field [formGroup] = "params" appearance="outline" class="w-full">
                            <input (keydown.enter)='getCandidates(true)' formControlName = "reclutador" matInput type="text" class = "validate" placeholder="" name = "reclutador">
                        </mat-form-field>
                 </div> -->

      </div>
      <div class="flex flex-row justify-end">
        <div class="px-3">
          <a mat-button class="gray" (click)="limpiar()">
            Limpiar
          </a>
        </div>
        <div class="px-3">
          <a mat-button style="background-color: #06589F; color: white;" (click)="getCandidates(true)">
            Buscar
          </a>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center py-3">
      <div class="font-medium text-base" style="color: rgba(0,0,0,.54);">
        Empleados: {{length}}
      </div>
      <div class="ml-auto">
        <a mat-mini-fab color="" class="ml-auto mat-elevation-z0 flex justify-center items-center"
          style="background-color: #00B92D;" routerLink="/home/candidatedetail">
          <mat-icon class="pb-1"
            style="color: white; background-color: #00B92D; width: unset; height: unset;">add</mat-icon>
        </a>
      </div>
    </div>
    <div *ngIf="dataSource&&dataSource.length>0">
      <div class="w-full py-3  ">
        <mat-table [dataSource]="dataSource" class="mat-elevation-z0    cursor-default	">

          <!-- <ng-container matColumnDef="numero" sticky  >
                        <mat-header-cell *matHeaderCellDef class="centered nume smaller-cell"> No. </mat-header-cell>
                        <mat-cell *matCellDef="let element"   class="padded cursor-pointer nume smaller-cell" >
                            <div class = "mobile-label w-1/4 md:w-auto">No.</div>
                            <div class = "break-words w-full md:w-auto md:text-center text-left md:pl-0 pl-3  text-xs">{{element.cdT_NUMBER}}</div>

                        </mat-cell>
                    </ng-container> -->

          <ng-container matColumnDef="fecha" sticky>
            <mat-header-cell *matHeaderCellDef class="centered  small-cell">Creado</mat-header-cell>
            <mat-cell *matCellDef="let element" class=" small-cell" style="left: 0;">
              <div class="mobile-label w-1/4 md:w-auto">Creado:</div>
              <div class="break-words w-full md:w-auto md:text-center text-left md:pl-0 pl-3 text-xs">
                {{element.cdT_CREATED_DATE| dateFormat }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="reclutador" sticky>
            <mat-header-cell *matHeaderCellDef class="centered  medium-cell"> Reclutador </mat-header-cell>
            <mat-cell *matCellDef="let element" class="padded  medium-cell">
              <div class="mobile-label w-1/4 md:w-auto">Reclutador</div>
              <div *ngIf="element.cdT_CREATED_BY_INFO"
                class="break-words w-full md:w-auto md:text-center text-left md:pl-0 pl-3 text-xs">
                {{element.cdT_CREATED_BY_INFO.anU_NAME}}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="empleado" sticky>
            <mat-header-cell *matHeaderCellDef class="centered  medium-cell"> Empleado </mat-header-cell>
            <mat-cell *matCellDef="let element" class="padded cursor-pointer  medium-cell hover:bg-gray-200 rounded"
              matTooltip="Ir al detalle de {{element.cdT_NAME}}" routerLink="/home/candidatedetail"
              [queryParams]="{id:element.cdT_ID}">
              <div class="mobile-label w-1/4 md:w-auto">Empleado</div>
              <div style="color: #06589F;"
                class="break-words w-full md:w-auto md:text-center text-left md:pl-0 pl-3 text-xs font-bold">
                {{element.cdT_NAME}} {{element.cdT_PATERNAL_SURNAME}} {{element.cdT_MATERNAL_SURNAME}}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="puesto">
            <mat-header-cell *matHeaderCellDef class="centered  medium-cell"> Puesto </mat-header-cell>
            <mat-cell *matCellDef="let element" class="padded  medium-cell">
              <div class="mobile-label w-1/4 md:w-auto">Puesto</div>

              <div *ngIf="element.cdT_JOB_INFO" class="flex">
                <div class="break-words w-full md:w-auto md:text-center text-left md:pl-0 pl-3 text-xs">
                  {{element.cdT_JOB_INFO.joB_NAME}}</div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="departamento">
            <mat-header-cell *matHeaderCellDef class="centered  medium-cell"> Departamento </mat-header-cell>
            <mat-cell *matCellDef="let element" class="padded  medium-cell">
              <div class="mobile-label w-1/4 md:w-auto">Departamento</div>
              <div *ngIf="element.cdT_DEPARTMENT_INFO" class="flex">
                <div class="break-words w-full md:w-auto md:text-center text-left md:pl-0 pl-3 text-xs">
                  {{element.cdT_DEPARTMENT_INFO.deP_NAME}}</div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sucursal">
            <mat-header-cell *matHeaderCellDef class="centered  medium-cell"> Sucursal </mat-header-cell>
            <mat-cell *matCellDef="let element" class="padded medium-cell">
              <div class="mobile-label w-1/4 md:w-auto">Sucursal</div>
              <div *ngIf="element.cdT_BRANCH_OFFICE_INFO" class="flex">
                <div class="break-words w-full md:w-auto md:text-center text-left md:pl-0 pl-3 text-xs">
                  {{element.cdT_BRANCH_OFFICE_INFO.broF_NAME}}</div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="telefono">
            <mat-header-cell *matHeaderCellDef class="centered medium-cell"> Celular </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="Llamar a {{element.cdT_NAME}}"
              class="padded  medium-cell hover:bg-gray-200 rounded">
              <span class="mobile-label w-1/4 md:w-auto">Celular</span>
              <a class="break-words w-full md:w-auto md:text-center text-left md:pl-0 pl-3 text-xs"
                href="tel:+{{element.cdT_PHONE_NUMBER_ONE}}">{{element.cdT_PHONE_NUMBER_ONE}}</a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="correo">
            <mat-header-cell *matHeaderCellDef class="centered email-cell"> Correo electrónico </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="Enviar correo a {{element.cdT_NAME}}"
              class="hover:bg-gray-200 rounded  email-cell">
              <span class="mobile-label w-1/4 md:w-auto">Correo electrónico</span>
              <a class="break-words w-full md:w-auto md:text-center text-left md:pl-0 pl-3 text-xs"
                href="mailto:{{element.cdT_EMAIL}}">{{element.cdT_EMAIL}}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="cv" stickyEnd>
            <mat-header-cell *matHeaderCellDef class="centered smaller-cell"> CV </mat-header-cell>
            <mat-cell *matCellDef="let element" class="padded smaller-cell">
              <span class="mobile-label w-1/4 md:w-auto">CV</span>
              <a *ngIf="element.cv!=null" matTooltip="Ver CV de {{element.cdT_NAME}}"
                class="w-full md:w-auto md:text-center text-left md:pl-0 pl-3 text-xs hover:bg-gray-200 rounded"
                style="color: #06589F;" (click)="openCv(element)"><b>Ver</b></a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="estatus" stickyEnd>
            <mat-header-cell *matHeaderCellDef class="centered  medium-cell"> Progreso </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="Cambiar progreso de {{element.cdT_NAME}}"
              class="padded  medium-cell hover:bg-gray-200 rounded">
              <span class="mobile-label w-1/4 md:w-auto">Progreso</span>
              <a style="color: #06589F;" (click)="statusChange(element,0)" class="text-xs"><b
                  *ngIf="element.cdT_STATUS_INFO">{{element.cdT_STATUS_INFO.stS_NAME}}</b></a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="situacion" stickyEnd>
            <mat-header-cell *matHeaderCellDef class="centered font-medium  medium-cell"> Estatus </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="Cambiar estatus de {{element.cdT_NAME}}"
              class="padded  medium-cell hover:bg-gray-200 rounded">
              <span class="mobile-label w-1/4 md:w-auto">Estatus</span>
              <a style="color: #06589F;" (click)="statusChange(element,1)" class="text-xs"><b
                  *ngIf="element.cdT_STATUS_CANDIDATE_INFO">{{element.cdT_STATUS_CANDIDATE_INFO.stsC_NAME}}</b></a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="acciones" stickyEnd>
            <mat-header-cell *matHeaderCellDef class="centered large-cell">Acciones</mat-header-cell>
            <mat-cell *matCellDef="let element" class="padded  large-cell">
              <span class="mobile-label w-1/4 md:w-auto">Acciones</span>
              <div class="flex justify-center flex-wrap md:flex-row ">
                <div class="flex items-center justify-center px-1 py-2 md:py-0 w-1/2 md:w-auto">
                  <mat-icon (click)="openChat(element)" matTooltip="Mensajes sobre el empleado"
                    class="cursor-pointer material-icons-outlined"
                    style="border-radius:4px; color: white; width: unset; height: unset; font-size: 28px; background-color: #06589F; padding: 3px; ">
                    chat_bubble_outline
                  </mat-icon>
                </div>
                <div class="flex items-center justify-center px-1 py-2 md:py-0 w-1/2 md:w-auto">
                  <a [matMenuTriggerFor]="options">
                    <mat-icon matTooltip="Menú de opciones" class="cursor-pointer material-icons-outlined"
                      style="border-radius:4px; color: black; width: unset; height: unset; font-size: 28px; background-color: #c4c4c4; padding: 3px; ">
                      menu
                    </mat-icon>
                  </a>
                </div>
                <mat-menu #options="matMenu">
                  <div *ngIf="1+1==2" class="flex items-center  justify-start px-1  py-2 w-full">
                    <div matTooltip="Generar liga para carga de documentos"
                      class="flex items-center justify-around cursor-pointer"
                      *ngIf="element.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO==null||!isTokenExpired(element.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO.tkcD_DUE_DATE)"
                      (click)="createCandidateToken(element.cdT_ID)">
                      <mat-icon class=" material-icons-outlined"
                        style="border-radius:4px; color: black; width: unset; height: unset; font-size: 28px; background-color: #d3d0ce; padding: 3px; ">
                        link
                      </mat-icon>
                      <div class="px-2 text-xs   md:flex font-medium">
                        Generar liga de empleado
                      </div>
                    </div>
                    <div matTooltip="Obtener liga para carga de documentos"
                      class="flex items-center justify-around cursor-pointer"
                      *ngIf="element.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO!=null&&isTokenExpired(element.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO.tkcD_DUE_DATE)"
                      (click)="openDialog(3,element.tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO.tkcD_TOKEN)">
                      <mat-icon class=" material-icons-outlined"
                        style="border-radius:4px; color: white; width: unset; height: unset; font-size: 28px; background-color: #fa6928; padding: 3px; ">
                        link
                      </mat-icon>
                      <div class="px-2 text-xs   md:flex font-medium">
                        Copiar liga de empleado
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center  justify-start px-1  py-2  w-full">
                    <a matTooltip="Descargar expediente"
                      href="{{url}}/api/CandidateDocumentsExternal/DownloadZipCandidateDocuments?candidateId={{element.cdT_ID}}"
                      class="flex items-center justify-around cursor-pointer ">
                      <mat-icon class="material-icons-outlined"
                        style="border-radius:4px; color: white; width: unset; height: unset; font-size: 28px; background-color: #00B92D; padding: 3px; ">
                        download
                      </mat-icon>
                      <div class="px-2 text-xs   md:flex font-medium">
                        Descarga expediente del empleado
                      </div>
                    </a>
                  </div>
                  <div class="flex items-center  justify-start px-1  py-2  w-full">
                    <div matTooltip="Eliminar empleado" class="cursor-pointer  flex items-center justify-around"
                      (click)="deleteCandidate(element)">
                      <mat-icon class="material-icons-outlined"
                        style="border-radius:4px; color: white; width: unset; height: unset; font-size: 28px; background-color: #FA284F; padding: 3px; ">
                        delete
                      </mat-icon>
                      <div class="px-2 text-xs   md:flex font-medium">
                        Eliminar empleado
                      </div>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
        [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex">
      </mat-paginator>
    </div>
    <div *ngIf="!dataSource||dataSource.length==0" class="flex items-center justify-center">
      <div class="font-medium text-2xl text-gray-600 py-8">
        No hay empleados que mostrar
      </div>
    </div>
  </div>
</div>
