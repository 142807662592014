<div class = " text-center text-lg font-medium mb-2" style="color: #06589F;">
    {{data[1]}}
</div>

<div [innerHTML]="data[2]" class = "text-left text-base font-medium gray">
    <!-- {{data[2]}} -->
</div>

<div class = "flex justify-center py-4">
    <ng-lottie *ngIf = "data[0] == 'Exito'" width = "70px" height = "70px" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
    <ng-lottie *ngIf = "data[0] == 'Error'" width = "70px" height = "70px" [options]="options2" (animationCreated)="animationCreated($event)"></ng-lottie>
    <img  *ngIf = "data[0] == 'loading'" src="/assets/animations/haibu-loading.gif" style="height: 120px; width: 120px;" >
</div>

<div class = "flex justify-center ">
    <button id="responseListDialogAccept" (click) = "cerrar()" mat-button class = "p-2 w-1/2 text-base" style = "background-color: #28B7FA; color: white; line-height: 40px;">{{"responseAnchorDialogAccept" | translate}}</button>
</div>
