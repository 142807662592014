import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  emailPattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,63}$";

  constructor() { }

  camposIguales(campo1: string, campo2: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const pass1 = formGroup.get(campo1)?.value;
      const pass2 = formGroup.get(campo2)?.value;

      if (pass1 !== pass2) {
        formGroup.get(campo2)?.setErrors({ ...formGroup.get(campo2)?.errors, noIguales: true });
        return { noIguales: true };
      } else {
        const errors = formGroup.get(campo2)?.errors;
        if (errors) {
          delete errors['noIguales'];
          if (Object.keys(errors).length === 0) {
            formGroup.get(campo2)?.setErrors(null);
          } else {
            formGroup.get(campo2)?.setErrors(errors);
          }
        }
        return null;
      }
    };
  }

}
