import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private loader: Loader;

  constructor() {
    this.loader = new Loader({
      apiKey: 'AIzaSyCP59wyi3P28fzDF1Eb6Ajsx9gqRNtoFPs',  // Reemplaza con tu clave de Google Maps
      version: 'weekly',
      libraries: ['places'],
    });
  }

  loadGoogleMaps(): Promise<any> {
    return this.loader.load();
  }
}
