<div class="flex flex-row gap-0 items-center phone-border" [ngClass]="customclass">
    <div class="relative w-2/6">
        <mat-select [(ngModel)]="lada" [tabindex]="tabindex1" [disabled]="disabled" (selectionChange)="sendValue()"
            class="w-full lada-select px-2">
            <mat-option *ngFor="let country of countries" [value]="country">
                {{ country.country }} ({{ country.lada }})
            </mat-option>
        </mat-select>
    </div>
    <div class="relative w-4/6 h-full">
        <input matInput class="w-full custom-input" type="text" [(ngModel)]="value" (input)="sendValue()"
            [tabindex]="tabindex2" [maxlength]="10" [placeholder]="placeholder" [disabled]="disabled"
            (focus)="onFocus()" (blur)="onBlur()" (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
            oninput="this.value = this.value.replace(/[^0-9]/g, '');">
    </div>
</div>