import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Question } from 'src/app/interfaces/question';

@Component({
  selector: 'app-question-form-card',
  templateUrl: './question-form-card.component.html',
  styleUrls: ['./question-form-card.component.css']
})
export class QuestionFormCardComponent implements OnInit {
  @Input() question: Question;
  @Input() index: number;
  @Input() deleteAvailable: boolean = false;
  @Input() seted: boolean = true;

  @Output() delete = new EventEmitter<void>();
  @Output() duplicate = new EventEmitter<void>();

  questionTypes = ['YES_NO', 'MULTIPLE_CHOICE', 'CHECKBOXES', 'OPEN_ENDED'];
  selectedOption = 0;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (['MULTIPLE_CHOICE', 'CHECKBOXES', 'YES_NO'].includes(this.question.questioN_TYPE)) {
      this.selectedOption = this.question.options.findIndex(option => option.iS_CORRECT);
    } else if (this.question.questioN_TYPE === 'OPEN_ENDED') {
      this.question.options = [];
    }
  }

  changeType(event: any) {
    switch (event.value) {
      case 'YES_NO':
        this.question.options = [
          {
            optioN_TEXT: "Yes",
            iS_CORRECT: true,
            optioN_POSITION_NUMBER: 1
          },
          {
            optioN_TEXT: "No",
            iS_CORRECT: false,
            optioN_POSITION_NUMBER: 2
          }
        ];
        this.selectedOption = 0;
        break;
      case 'MULTIPLE_CHOICE':
        this.question.options = [
          {
            optioN_TEXT: this.translate.instant("Option1"),
            iS_CORRECT: true,
            optioN_POSITION_NUMBER: 1
          },
          {
            optioN_TEXT: this.translate.instant("Option2"),
            iS_CORRECT: false,
            optioN_POSITION_NUMBER: 2
          }
        ];
        this.selectedOption = 0;
        break;
      case 'CHECKBOXES':
        this.question.options = [
          {
            optioN_TEXT: this.translate.instant("Option1"),
            iS_CORRECT: true,
            optioN_POSITION_NUMBER: 1
          },
          {
            optioN_TEXT: this.translate.instant("Option2"),
            iS_CORRECT: false,
            optioN_POSITION_NUMBER: 2
          }
        ];
        break;
      case 'OPEN_ENDED':
        this.question.options = [];
        break;
    }
  }

  addOption() {
    this.question.options.push({
      optioN_TEXT: "",
      iS_CORRECT: false,
      optioN_POSITION_NUMBER: this.question.options.length + 1
    });
  }

  getTourAnchor() {
    if (!this.seted) {
      this.seted = true;
      return 'catalogs.job.quest.add.modal.questionAnswer';
    }
  }

  changeCorrectOption(index: number) {
    this.question.options.forEach((option, i) => {
      if (i === index) {
        option.iS_CORRECT = true;
      } else {
        option.iS_CORRECT = false;
      }
    });
    this.selectedOption = index;
  }

  deleteOption(index: number) {
    this.question.options.splice(index, 1);
    if (['MULTIPLE_CHOICE', 'CHECKBOXES'].includes(this.question.questioN_TYPE) && this.selectedOption == index) {
      this.selectedOption = 0;
    }
    // update key positioN_NUMBER for each option
    this.question.options.forEach((option, index) => {
      option.positioN_NUMBER = index + 1;
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.question.options, event.previousIndex, event.currentIndex);
    // update key positioN_NUMBER for each option
    this.question.options.forEach((option, index) => {
      option.positioN_NUMBER = index + 1;
      // if option is correct, update selectedOption
      if (option.iS_CORRECT && ['MULTIPLE_CHOICE', 'YES_NO'].includes(this.question.questioN_TYPE)) {
        this.selectedOption = index;
      }
    });
  }

  onDuplicate() {
    this.duplicate.emit();
  }

  onDelete() {
    this.delete.emit();
  }

  checkIfAtLeastOneTrue() {
    return this.question.options.some(option => option.iS_CORRECT);
  }
}
