import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { AddOrEditJobComponent } from '../dialogs/add-or-edit-job/add-or-edit-job.component';
import { AddOrEditDepartmentComponent } from '../dialogs/add-or-edit-department/add-or-edit-department.component';
import { AddOrEditBranchComponent } from '../dialogs/add-or-edit-branch/add-or-edit-branch.component';
import { TranslateService } from '@ngx-translate/core';
import { ResponseDialogComponent } from '../dialogs/response-dialog/response-dialog.component';
import { VacancyDialogComponent } from '../dialogs/vacancy-dialog/vacancy-dialog.component';
import { PuzzleService } from './puzzle.service';
import { LocalService } from './local.service';

export interface TourStep {
  anchorId: string;
  title: string;
  content: string;
  route?: string;
  openModal?: any;
  config?: any;
  keepOpen?: boolean;
  removeBlocker?: boolean;
  requireField?: boolean;
  disableBack?: boolean;
  final?: boolean;
  wait?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CustomTourService {
  private steps: TourStep[] = [];
  private currentStepIndex = new BehaviorSubject<number | null>(null); // Emite `null` cuando finaliza el tour
  public currentStep$ = this.currentStepIndex.asObservable();
  public highlightedAnchorId$ = new BehaviorSubject<string | null>(null);
  nombreFieldStatus$ = new Subject<boolean>();
  isNombreFieldFilled = false;
  descriptionFieldStatus$ = new Subject<boolean>();
  isDescriptionFieldFilled = false;
  departmentFieldStatus$ = new Subject<boolean>();
  isDepartmentFieldFilled = false;
  bossFieldStatus$ = new Subject<boolean>();
  isBossFieldFilled = false;
  activeTour = false;

  private refreshVacanciesSubject = new Subject<void>();
  refreshVacancies$ = this.refreshVacanciesSubject.asObservable();

  private dialogRef: MatDialogRef<any> | null = null;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private puzzleService: PuzzleService,
    private localService: LocalService
  ) {
    this.nombreFieldStatus$.subscribe(isFilled => {
      this.isNombreFieldFilled = isFilled;
    });
    this.descriptionFieldStatus$.subscribe(isFilled => {
      this.isDescriptionFieldFilled = isFilled;
    });
    this.departmentFieldStatus$.subscribe(isFilled => {
      this.isDepartmentFieldFilled = isFilled;
    });
    this.bossFieldStatus$.subscribe(isFilled => {
      this.isBossFieldFilled = isFilled;
    });
  }

  initialize(steps: TourStep[]) {
    this.steps = steps;
    this.currentStepIndex.next(0);
    this.updateHighlight();
  }

  start() {
    this.showStep(0);
    this.activeTour = true;
  }

  next() {
    const nextIndex = (this.currentStepIndex.value ?? 0) + 1;
    if (nextIndex < this.steps.length) {
      this.showStep(nextIndex);
    }
  }

  previous() {
    const prevIndex = (this.currentStepIndex.value ?? 0) - 1;
    if (prevIndex >= 0) {
      this.showStep(prevIndex);
    }
  }

  end() {
    this.activeTour = false;
    this.currentStepIndex.next(null); // Emite `null` para indicar que el tour ha terminado
    this.highlightedAnchorId$.next(null); // Elimina cualquier resaltado
    const tokencito = this.localService.getJsonValue('token');
    this.puzzleService.updateUserTutorialStatus().subscribe(async () => {
      // pedir otro token
      const user = {
        UserId: tokencito.data.id,
        CompanyId: tokencito.data.companyId
      }
      const response = await this.puzzleService.getTokenForCompany(user).subscribe((res) => {
        if (res.isSuccess) {
          this.localService.setJsonValue('token', res);
        }
      });
    });
  }

  showStep(index: number) {
    const step = this.steps[index];

    // Si el paso requiere un campo lleno, verifica a través del Subject
    if (step.anchorId == 'addJob.description' && step.requireField && !this.isNombreFieldFilled) {
      console.warn('El campo nombre está vacío. Completar antes de avanzar.');
      return; // No avanzar si el campo está vacío
    }

    // Si el paso requiere un campo lleno, verifica a través del Subject
    if (step.anchorId == 'addJob.save' && step.requireField && !this.isDescriptionFieldFilled) {
      console.warn('El campo descripción está vacío. Completar antes de avanzar.');
      return; // No avanzar si el campo está vacío
    }

    // Si el paso requiere un campo lleno, verifica a través del Subject
    if (step.anchorId == 'departments.save' && step.requireField && !this.isNombreFieldFilled) {
      console.warn('El campo nombre está vacío. Completar antes de avanzar.');
      return; // No avanzar si el campo está vacío
    }

    // Si el paso requiere un campo lleno, verifica a través del Subject
    if (step.anchorId == 'branches.location' && step.requireField && !this.isNombreFieldFilled) {
      console.warn('El campo nombre está vacío. Completar antes de avanzar.');
      return; // No avanzar si el campo está vacío
    }

    if (step.anchorId == 'branches.save' && step.requireField && !this.isDescriptionFieldFilled) {
      console.warn('El campo ubicación está vacío. Completar antes de avanzar.');
      return; // No avanzar si el campo está vacío
    }

    if (step.anchorId == 'vacancies.branch' && step.requireField && !this.isNombreFieldFilled) {
      console.warn('El campo puesto está vacío. Completar antes de avanzar.');
      return; // No avanzar si el campo está vacío
    }

    if (step.anchorId == 'vacancies.department' && step.requireField && !this.isDescriptionFieldFilled) {
      console.warn('El campo sucursal está vacío. Completar antes de avanzar.');
      return; // No avanzar si el campo está vacío
    }

    if (step.anchorId == 'vacancies.boss' && step.requireField && !this.isDepartmentFieldFilled) {
      console.warn('El campo departamento está vacío. Completar antes de avanzar.');
      return; // No avanzar si el campo está vacío
    }

    if (step.anchorId == 'vacancies.info' && step.requireField && !this.isBossFieldFilled) {
      console.warn('El campo boss está vacío. Completar antes de avanzar.');
      return; // No avanzar si el campo está vacío
    }

    this.openDialogOrContinue(index, step);
  }

  private openDialogOrContinue(index: number, step: TourStep) {
    if (step.route && this.router.url !== step.route) {
      this.router.navigateByUrl(step.route).then(() => {
        const overlayElement = document.querySelector('.overlay');
        if (overlayElement) {
          if (step.removeBlocker) {
            overlayElement.classList.add('overlay-no-block');
          } else {
            overlayElement.classList.remove('overlay-no-block');
          }
        }
        if (!step.keepOpen) {
          this.dialog.closeAll();
        }

        if (step.openModal) {
          const dialogId = 'tour-dialog-opened'; // Agrega un ID único para el diálogo

          // Si el diálogo no está abierto, ábrelo
          if (!this.dialog.getDialogById(dialogId)) {
            this.dialog.closeAll();
            this.dialogRef = this.dialog.open(step.openModal, { ...step.config, id: dialogId, disableClose: true });

            this.dialogRef.afterOpened().subscribe(() => {
              setTimeout(() => {
                this.currentStepIndex.next(index);
                this.updateHighlight();
              }, 100);
            });

            this.dialogRef.beforeClosed().subscribe(() => {
              const currentStep = this.getCurrentStep();
              if (currentStep.anchorId == 'catalogs.departments') {
                (this.dialogRef.componentInstance as AddOrEditJobComponent).submitForm();
              }
              if (currentStep.anchorId == 'catalogs.branches') {
                (this.dialogRef.componentInstance as AddOrEditDepartmentComponent).submitForm();
              }
              if (currentStep.anchorId == 'vacancies.add' || currentStep.anchorId == 'vacancies.add.mobile') {
                (this.dialogRef.componentInstance as AddOrEditBranchComponent).submitForm();
              }
              if (currentStep.anchorId == 'vacancies.save' || currentStep.anchorId == 'vacancies.btn') {
                (this.dialogRef.componentInstance as VacancyDialogComponent).addVacancy();
              }

              //resetear variables
              this.nombreFieldStatus$.next(false);
              this.descriptionFieldStatus$.next(false);
            });

            this.dialogRef.afterClosed().subscribe((result) => {
              const currentStep = this.getCurrentStep();
              if (currentStep.anchorId == 'vacancies.save' || currentStep.anchorId == 'vacancies.btn') {
                if (result?.isSuccess) {
                  this.triggerRefreshVacancies();
                }
              }
            });
          } else {
            if (step.wait) {
              if (step.anchorId == 'branches.location') {
                (this.dialogRef.componentInstance as AddOrEditBranchComponent).addBranch();
              }
              setTimeout(() => {
                this.currentStepIndex.next(index);
                this.updateHighlight();
              }, 200);
            } else {
              this.currentStepIndex.next(index);
              this.updateHighlight();
            }
          }
        } else {
          this.currentStepIndex.next(index);
          this.updateHighlight();
        }
      });
    } else {
      this.currentStepIndex.next(index);
      this.updateHighlight();
    }
  }

  private updateHighlight() {
    const currentStep = this.getCurrentStep();
    this.highlightedAnchorId$.next(currentStep ? currentStep.anchorId : null);
  }

  getCurrentStep(): TourStep | undefined {
    return this.steps[this.currentStepIndex.value ?? 0];
  }

  openResponseDialog(responseType: string, message: string, showNewButton: boolean = false, vacancyId?: string) {
    const data = {
      responseType: responseType,
      message: message,
      showNewButton: showNewButton,
      vacancyId: vacancyId
    };

    const responseDialogRef = this.dialog.open(ResponseDialogComponent, {
      width: '700px',
      data: data
    });
    responseDialogRef.afterClosed().subscribe();
  }

  triggerRefreshVacancies() {
    this.refreshVacanciesSubject.next();
  }
}
