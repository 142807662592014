import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-freemium-dialog',
  templateUrl: './freemium-dialog.component.html',
  styleUrls: ['./freemium-dialog.component.css']
})
export class FreemiumDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scheduleMeet() {
    window.open('https://meet.goodtime.io/w/haibuhrcom/jgasteasoro/video-call/intro', '_blank');
  }

}
