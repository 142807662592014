import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { LocalService } from 'src/app/services/local.service';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@Component({
  selector: 'app-lang-datepicker',
  templateUrl: './lang-datepicker.component.html',
  styleUrls: ['./lang-datepicker.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class LangDatepickerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() date: string | Date;
  @Input() min: Date;
  @Input() max: Date;
  @Input() required: boolean = false;
  @Input() tabindex: number = 0;
  @Input() disabled: boolean = false;
  @Input() height: string = 'altura-48';
  @Input() borde: string = '#BDBDBD';
  @Output() datechange = new EventEmitter<string>();
  @Output() focus = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();

  selectedDate: Date;
  borderColor: string = '#BDBDBD';

  @Input() clickOutsideDirective: ClickOutsideDirective;
  @ViewChild('dp') datepicker: MatDatepicker<Date>;

  constructor(
    private _adapter: DateAdapter<any>,
    private localService: LocalService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.borde) {
      this.borderColor = changes.borde.currentValue;
    }

    if (changes['date']) {
      const newDateValue = changes['date'].currentValue;

      if (newDateValue instanceof Date) {
        this.selectedDate = newDateValue;
      } else if (typeof newDateValue === 'string') {
        if (newDateValue.includes('T')) {
          this.date = newDateValue.split('T')[0];
        }
        const [year, month, day] = this.date.toString().split('-');
        this.selectedDate = new Date(+year, +month - 1, +day);
      }
    }
  }

  ngAfterViewInit() {
    if (this.clickOutsideDirective) {
      this.datepicker.openedStream.subscribe(() => {
        this.clickOutsideDirective.setDatepickerOpen(true);
      });
      this.datepicker.closedStream.subscribe(() => {
        this.clickOutsideDirective.setDatepickerOpen(false);
      });
    }
  }

  ngOnInit(): void {
    this.borderColor = this.borde;
    let lang = 'es'
    let tokencito = this.localService.getJsonValue('token');
    if (tokencito?.data?.languagePreference) {
      lang = this.mapBrowserLangToAppLang(tokencito?.data?.languagePreference);
    } else if (this.localService.getJsonValue('lang')) {
      lang = this.mapBrowserLangToAppLang(this.localService.getJsonValue('lang'));
    }
    this._adapter.setLocale(lang);

    if (this.date) {
      if (this.date instanceof Date) {
        // Si es de tipo Date, simplemente lo asignamos a selectedDate
        this.selectedDate = this.date;
      } else if (typeof this.date === 'string') {
        // Si es un string, verificamos si contiene 'T' y lo procesamos
        if (this.date.includes('T')) {
          this.date = this.date.split('T')[0];
        }

        const [year, month, day] = this.date.split('-');
        this.selectedDate = new Date(+year, +month - 1, +day);
      } else {
        console.warn('El formato de fecha no es válido.');
      }
    }
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'eng': 'en',
      'esp': 'es'
    };
    return langMap[browserLang] || 'es';
  }

  dateChanged(event: any) {
    this.datechange.emit(event.value.format('YYYY-MM-DD'));
  }

  openDatepicker() {
    this.datepicker.open();
  }

  changeLang(lang: string) {
    this._adapter.setLocale(this.mapBrowserLangToAppLang(lang));
  }

  onFocus() {
    this.borderColor = 'black';
    this.focus.emit();
  }

  onBlur() {
    this.borderColor = this.borde;
    this.blur.emit();
  }
}
