<app-navbar></app-navbar>
<div class="mt-28 py-6 px-4 md:px-20 lg:px-28 ">
  <div class="text-center">
    <div class="green-header mb-5">{{ 'blogHeader.title' | translate }}</div>
    <div class="gray-header">{{ 'blogHeader.description' | translate }}</div>
  </div>

  <!-- Último post destacado -->
  <div class="my-6">
    <div *ngIf="lastPost" class="bg-white rounded-lg border-gray grid grid-cols-1 md:grid-cols-2 overflow-hidden cursor-pointer scale-card" (click)="goToPost(lastPost)">
      <figure class="relative h-64 md:h-80 lg:h-96">
        <!-- Imagen principal con skeleton -->
        <div class="skeleton-image h-full w-full">
          <img [src]="lastPost?.eH_BLOG_IMG_BLOG" alt="Imagen del Blog"
            class="w-full h-full object-cover rounded-l md:rounded-none" (load)="removeSkeleton($event)">
        </div>
        <!-- Imagen de perfil del autor -->
        <img [src]="lastPost?.eH_BLOG_PFP_AUTHOR" alt="Foto del Autor"
          class="absolute w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full bottom-4 right-4 object-cover object-center"
          (load)="removeSkeleton($event)">
      </figure>
      <div class="px-6 py-4 flex flex-col justify-between">
        <div>
          <div class="gray-title mb-2">{{ lastPost?.eH_BLOG_TITLE }}</div>
          <p class="gray-description" [innerHTML]="lastPost?.eH_BLOG_DESCRIPTION"></p>
        </div>
        <div class="gray-autor">
          {{ lastPost?.eH_BLOG_AUTHOR }} • {{ lastPost?.eH_BLOG_CREATED_DATE | localDate }}
        </div>
      </div>
    </div>
    <div *ngIf="isLoading && !lastPost" class="animate-pulse bg-gray-200 rounded-lg h-64"></div>
  </div>

  <app-haibu-banner></app-haibu-banner>

  <!-- Lista de otros posts -->
  <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
    <div class="bg-white rounded-lg border-gray grid grid-cols-1 cursor-pointer scale-card" *ngFor="let post of blogs.slice(1)"
      (click)="goToPost(post)">
      <figure class="relative h-40 md:h-48 lg:h-56"> <!-- Altura mayor en desktop -->
        <!-- Imagen del blog en otros posts con skeleton -->
        <div class="skeleton-image h-full w-full">
          <img [src]="post.eH_BLOG_IMG_BLOG" alt="Imagen del Blog" class="w-full h-full object-cover rounded-t"
            (load)="removeSkeleton($event)">
        </div>
        <!-- Imagen de perfil del autor en otros posts con skeleton -->
        <img [src]="post.eH_BLOG_PFP_AUTHOR" alt="Foto del Autor"
          class="absolute w-12 h-12 md:w-16 md:h-16 rounded-full bottom-2 right-2 object-cover"
          (load)="removeSkeleton($event)">
      </figure>
      <div class="px-6 py-6 flex flex-col justify-between"> <!-- Espaciado adicional para contenido -->
        <div>
          <div class="gray-title2 mb-2">{{ post.eH_BLOG_TITLE }}</div>
        </div>
        <div class="gray-autor">{{ post.eH_BLOG_AUTHOR }} • {{ post.eH_BLOG_CREATED_DATE | localDate }}</div>
      </div>
    </div>
    <div *ngIf="isLoading || !blogs">
      <div *ngFor="let placeholder of [1, 2, 3]" class="bg-white rounded-lg border-gray p-4 animate-pulse">
        <div class="w-full h-32 bg-gray-200 rounded mb-4"></div>
        <div class="w-3/4 h-4 bg-gray-200 rounded mb-2"></div>
        <div class="w-1/2 h-4 bg-gray-200 rounded"></div>
      </div>
    </div>
  </div>
</div>
