import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeComponent } from '../../home/home.component';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-response-list-dialog',
  templateUrl: './response-list-dialog.component.html',
  styleUrls: ['./response-list-dialog.component.css']
})
export class ResponseListDialogComponent implements OnInit {

  constructor( private dialogRef: MatDialogRef<HomeComponent>, @Inject(MAT_DIALOG_DATA) public data:string[], @Inject(MAT_DIALOG_DATA) public response:string ) {}

  options: AnimationOptions = {
    path: '/assets/animations/checkmark.json',
  };

  options2: AnimationOptions = {
    path: '/assets/animations/error.json',
  };

  options3: AnimationOptions = {
    path: '/assets/animations/loading.json',
  };


  animationCreated(animationItem: AnimationItem): void {
  }

  ngOnInit(): void {
  }

  cerrar(){
    this.dialogRef.close();
  }

}
