<div class="p-2 pt-0 relative">
    <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
        <button id="closeModalChangeStatus2" mat-icon-button (click)="cancel()" tabindex="-1">
            <mat-icon class="close-x-primary">close</mat-icon>
        </button>
    </div>
    <div class="pb-3 text-center title-text-modal">
        {{ title | translate }}
    </div>
    <div mat-dialog-content class="max-h-[60%]">
        <div class="grid grid-cols-1 pb-3">
            <div *ngFor="let file of files; let i = index" class="flex flex-col items-center justify-center">
                <img [src]="fileToUrl(file)" class="w-full h-auto" alt="photo">
                <app-custom-primary-button *ngIf="files.length > 1" id="candidateCopyQrDialog" size="auto-light" color="secondary"
                    class="text-center w-full mt-1 mb-2" (click)="deleteFile(i)" [disabled]="loading" textPadding="10px">
                    {{ 'deleteImage' | translate }}
                </app-custom-primary-button>
            </div>
        </div>

        <input #fileSelectImage id="fileSelect" type="file"
            accept=".doc,.docx,application/msword,application/vnd.ms-excel,.xlsx,.xls,image/*,application/pdf,.pdf"
            (click)="fileSelectImage.value = null" (change)="addFile($event)" class="hidden">
    </div>
    <div class="pt-4 w-full flex flex-row items-center justify-around gap-2">
        <app-custom-primary-button *ngIf="files.length < 3" id="candidateCopyQrDialog" size="super-small-light" color="secondary"
            class="text-center w-1/2 md:w-auto" (click)="fileSelectImage.click()" [disabled]="loading"
            textPadding="10px">
            {{ 'addImage' | translate }}
        </app-custom-primary-button>
        <app-custom-primary-button id="candidateDownloadQrDialog" size="super-small-light" color="secondary"
            class="text-center w-1/2 md:w-auto" (click)="combineImages()" [disabled]="loading" textPadding="10px">
            <span *ngIf="!loading">{{ 'Aceptar' | translate }}</span>
            <div *ngIf="loading" class="w-full flex justify-center">
                <mat-spinner diameter="20" color="primary"></mat-spinner>
            </div>
        </app-custom-primary-button>
    </div>
</div>