<div class="pb-6 text-xl text-center" style="color: #06589F;">
    {{ 'getNewFeatures' | translate }}<br>
    {{ 'getNewFeatures2' | translate }}
</div>

<div class="flex justify-center">
    <figure>
        <img src="assets/img/premium.png" alt="premium" class="w-28">
    </figure>
</div>

<div class="flex justify-center pt-6 pb-2 px-2 md:px-0">
    <div class="w-full md:w-auto">
        <app-custom-primary-button id="responseAnchorDialogAccept" color="primary" size="small" class="mx-auto" (click)="scheduleMeet()">
            {{"newFeaturesBtn" | translate}}
        </app-custom-primary-button>
    </div>
</div>