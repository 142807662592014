import { Component, OnInit } from '@angular/core';
import { Blog } from '../interfaces/blog';
import { ActivatedRoute, Router } from '@angular/router';
import { PuzzleService } from '../services/puzzle.service';
import { environment } from 'src/environments/environment';
import { Meta, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ResponseSmallDialogComponent } from '../dialogs/response-small-dialog/response-small-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-blog-id',
  templateUrl: './blog-id.component.html',
  styleUrls: ['./blog-id.component.css']
})
export class BlogIdComponent implements OnInit {

  blog: Blog;
  relatedBlogs: Blog[] = [];
  slug: string;
  imgPath: string = '';
  tagList: string[] = [];
  shareMenuOpen = false;
  shareMenuOpen2 = false;
  currentUrl = window.location.href;
  fbIcon: SafeResourceUrl = '../../assets/icons/facebook-icon.svg';
  linkedinIcon: SafeResourceUrl = '../../assets/icons/linkedin-icon.svg';
  whatsIcon: SafeResourceUrl = '../../assets/icons/icons8-whatsapp.svg';
  copyIcon: SafeResourceUrl = '../../assets/icons/link-created-icon.svg';
  defaultpfp: string = '../../assets/icons/default-pfp-icon.svg';
  defaultPicture: string = '../../assets/icons/icon-512x512.png';

  constructor(
    private route: ActivatedRoute,
    private puzzleService: PuzzleService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    public dialog: MatDialog,
    private translate: TranslateService,
  ) {
    this.imgPath = environment.imageUrl;
    // forzar q la pagina inicie hasta arriba
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.slug = params['slug'];
      if (this.slug && this.slug.trim() !== '') {
        this.getBlog(this.slug);
        window.scrollTo(0, 0); // Reinicia la posición de desplazamiento al cambiar de post
      } else {
        this.router.navigate(['/blog']);
      }
    });
  }


  getBlog(id: string) {
    // get blog
    this.puzzleService.getBlogById(id).subscribe(res => {
      this.blog = res;
      this.tagList = this.blog.tags.map(tag => tag.eH_SEO_TAG);
      this.setMetaTags();
      this.loadRelatedBlogs();
    });
  }

  setMetaTags() {
    if (this.blog) {
      // Configura el título
      this.titleService.setTitle('Blog - ' + this.blog.eH_BLOG_TITLE);

      // Meta descripción
      this.metaService.updateTag({
        name: 'description',
        content: this.blog.eH_BLOG_DESCRIPTION || 'Descripción del artículo de blog'
      });

      // Meta keywords
      this.metaService.updateTag({
        name: 'keywords',
        content: this.tagList.join(', ')
      });

      // Meta Open Graph (para redes sociales)
      this.metaService.updateTag({
        property: 'og:title',
        content: 'Blog - ' + this.blog.eH_BLOG_TITLE
      });
      this.metaService.updateTag({
        property: 'og:description',
        content: this.blog.eH_BLOG_DESCRIPTION || 'Descripción para redes sociales'
      });
      this.metaService.updateTag({
        property: 'og:image',
        content: (this.imgPath + this.blog.eH_BLOG_IMG_BLOG) || 'URL-de-la-imagen.jpg'
      });
      this.metaService.updateTag({
        property: 'og:url',
        content: `https://haibuhr.com/blog/${this.slug}`
      });
    }
  }

  navigateToBlog(): void {
    this.router.navigate(['/blog']);
  }

  toggleShareMenu(): void {
    this.shareMenuOpen = !this.shareMenuOpen;
  }

  toggleShareMenu2(): void {
    this.shareMenuOpen2 = !this.shareMenuOpen2;
  }

  copyLinkToClipboard(): void {
    navigator.clipboard.writeText(this.currentUrl).then(() => {
      // Mensaje de confirmación al usuario
      const clipboardMessage = this.translate.instant('linkCopied');
      const dialogRef = this.dialog.open(ResponseSmallDialogComponent, {
        width: '500px',
        data: ['Exito', clipboardMessage], // Puedes personalizar este mensaje según la estructura de tu app
      });
    }).catch(err => {
      console.error('Error al copiar el enlace:', err);
    });
  }

  loadRelatedBlogs(): void {
    const filters = {
      PageNumber: 1,
      PageSize: 5,  // Limitamos la solicitud a solo 5 blogs
      GeneralSearch: '', // Puedes ajustar los filtros según tus necesidades
      Title: '',
      SeoDescription: '',
      ActiveStatus: true,  // Suponiendo que queremos solo los blogs activos
      ExcludeBlogId: this.blog.eH_BLOG_ID // Excluimos el blog actual de la lista
    };

    this.puzzleService.getBlogs(filters).subscribe(
      (response) => {
        this.relatedBlogs = response.blogs.map((blog) => {
          blog.eH_BLOG_IMG_BLOG = blog.eH_BLOG_IMG_BLOG
            ? environment.imageUrl + blog.eH_BLOG_IMG_BLOG
            : this.defaultPicture;
          blog.eH_BLOG_PFP_AUTHOR = blog.eH_BLOG_PFP_AUTHOR
            ? environment.imageUrl + blog.eH_BLOG_PFP_AUTHOR
            : this.defaultpfp;
          return blog;
        });

      },
      (error) => {
        console.error('Error fetching related blogs', error);
      }
    );
  }

  goToPost(post: Blog) {
    this.router.navigate(['/blog', post.eH_BLOG_SLUG]);
  }
}
