<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
        <mat-icon class="close-x-primary">close</mat-icon>
    </button>
</div>

<div class="p-3 pt-0 relative">
    <div *ngIf="vacancy.jobInfo" class=" text-center title-text-modal">
        {{ 'candidateGenerateLinkQrDialog' | translate:{name: vacancy.jobInfo.joB_NAME} }}
    </div>
    <div mat-dialog-content>
        <div *ngIf="qrUrl" class="flex justify-center items-center">
            <qrcode #qrCode [qrdata]="qrUrl" [width]="256" [errorCorrectionLevel]="'M'" [elementType]="'img'"
                [imageSrc]="logoHaibu" [imageHeight]="75" [imageWidth]="75" [margin]="4" [scale]="1"></qrcode>
        </div>
        <div class="flex flex-col md:flex-row md:items-center md:justify-between">
            <app-custom-primary-button id="candidateCopyQrDialog" (click)="copyImage()" size="mini" color="secondary"
                class="text-center mb-2 md:mb-0 md:mr-2">
                {{ 'candidateCopyQrDialog' | translate }}
            </app-custom-primary-button>
            <app-custom-primary-button id="candidateDownloadQrDialog" (click)="saveAsImage()" size="mini"
                color="secondary" class="text-center">
                {{ 'candidateDownloadQrDialog' | translate }}
            </app-custom-primary-button>
        </div>
    </div>
</div>