import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Candidate } from 'src/app/interfaces/Candidate';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { ClientListCatalog } from '../../interfaces/client-list-catalog';
import { DatePipe } from '@angular/common'
import { Status } from 'src/app/interfaces/status';
import { candidateStatus } from 'src/app/interfaces/candidateStatus';
import { MatDialog } from '@angular/material/dialog';
import { ChangeStatusComponent } from 'src/app/dialogs/change-status/change-status.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { UpdateCandidateStaPro } from 'src/app/interfaces/UpdateCandidateStaPro';
import { ResponseDialogComponent } from 'src/app/dialogs/response-dialog/response-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { DocuViewerComponent } from 'src/app/dialogs/docu-viewer/docu-viewer.component';
import { MessagerChatComponent } from 'src/app/dialogs/messager-chat/messager-chat.component';
import { SwUpdate } from '@angular/service-worker';
import { constants } from 'src/app/services/constants';
import { environment } from 'src/environments/environment';
import { Job } from 'src/app/interfaces/job';
import { Department } from 'src/app/interfaces/department';
import { Branch } from 'src/app/interfaces/branch';
import { Vacancy } from 'src/app/interfaces/vacancy';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  length = 0;
  pageSize = 50;
  pageIndex = 0;
  pageSizeOptions = [50, 100, 200];
  showFirstLastButtons = true;
  dataSource: Candidate[] = [];
  progressId:string;
  progressList: Status[] = [];
  statusId:string;
  statusList: candidateStatus[] = [];
  dateStart: Date;
  dateEnd: Date;
  jobs: Job[];
  departments: Department[];
  branchs: Branch[];
  jobId:string;
  vacanT_ID:string;
  departmentId:string;
  branchId:string;
  url:string = environment.apiUrlUnsafe;
  iduser:string = '';
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  params = new FormGroup({
    progress: new FormControl(),
    status: new FormControl(),
    name: new FormControl(),
    reclutador: new FormControl(),
    vacancy: new FormControl(),
    department: new FormControl(),
    branch: new FormControl()
  });
  vacancy: Vacancy[];

  constructor(private swUpdate: SwUpdate,private puzzleService: PuzzleService,private localService: LocalService,public datepipe: DatePipe, public dialog: MatDialog ) {
    this.swUpdate.checkForUpdate();
  }

  ngOnInit(): void {

    let tokencito = this.localService.getJsonValue('token');
    this.iduser = tokencito.data.id;

    this.getCandidates(true);
    this.getCatalogs();

  }

  limpiar(){
    this.pageIndex = 0;

    this.range.reset();
    this.params.reset();

    this.progressId = '';
    this.statusId = '';
    this.jobId = '';
    this.departmentId = '';
    this.branchId = '';

    this.getCandidates(true);


  }

  getCatalogs(){
    this.puzzleService.getStatus(null,null,this.localService.getJsonValue('token').data.companyId,null).subscribe(response=>{
       this.progressList = response;
    });
    this.puzzleService.getCandidateStatus().subscribe(response=>{
       this.statusList = response;
    });
    this.getJobs();
    this.getDepartmentsOrdered();
    this.getBranchsOrdered();
  }

  onProgressSelect(progress:Status){
       this.progressId = progress.stS_ID;
  }

  onStatusSelect(status:candidateStatus){
    this.statusId = status.stsC_ID;
  }

  onBranchSelect(branch:Branch){
    this.branchId = branch.broF_ID;
  }

  onJobSelect(job:Job){
    this.jobId = job.joB_ID;
  }

  onDepartmentSelect(department:Department){
    this.departmentId = department.deP_ID;
  }


openCv(element){
  let dialog = this.dialog.open(DocuViewerComponent, {
    panelClass: 'custom-dialog-cv',
    maxWidth: '100vw !important',
    data: {data:element,header:"Curriculum vitáe"},
  });

  dialog.afterClosed().subscribe(result=>{

  });
}

openChat(element){
  let dialog = this.dialog.open(MessagerChatComponent, {
    width: '90vw',
    maxWidth:'90vw',
    data: {data:element},
  });

  dialog.afterClosed().subscribe(result=>{

  });
}


statusChange(element,mode){

  let dialog = this.dialog.open(ChangeStatusComponent, {
    width: '500px',
    data: {data:element,mode:mode},
  });

  dialog.afterClosed().subscribe(result=>{
    if(result==1){
      this.getCandidates(false);
    }
  });
}


deleteCandidate(candidate:Candidate){

  let data:string[]=[];
     data.push('Eliminar candidato');
    data.push(
      '¿Está seguro de que desea eliminar al candidato '+ candidate.cdT_NAME+"?"
    );


  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    width: '500px',
    data: data,
  });

  dialogRef.afterClosed().subscribe((result) => {
    if(result){

    let request = new UpdateCandidateStaPro();

    request.cdT_ACTIVE=false;
    request.cdT_ID = candidate.cdT_ID;
      this.puzzleService.deleteCandidate(request).subscribe(response=>{
        if(response.isSuccess){
          this.openResponseDialog('Exito',response.message);
        }else{
         this.openResponseDialog('Error',response.message);

       }
       this.getCandidates(false);

      });
    }
  });
}

downloadArchive(candidate:Candidate){}


handlePageEvent(event: PageEvent) {
   this.length = event.length;
  this.pageSize = event.pageSize;
  this.pageIndex = event.pageIndex;
  this.getCandidates(false);
}

createCandidateToken(candidateId:string){

  this.puzzleService.createCandidateToken(candidateId).subscribe(response=>{
     if(response.isSuccess){
      this.getCandidates(false);
    }else{
      this.openResponseDialog('Error',response.message);
    }
  });
}

isTokenExpired(expirationDate){
  let rightNow = new Date();
  let newDate = new Date(expirationDate);

  if(rightNow<newDate){
    return true;
  }else{
    return false;
  }
}


  getCandidates(force:boolean){

    if(force){
      this.pageIndex = 0;
    }

    this.puzzleService.getAllCandidates(
       this.iduser,
       this.pageSize,
       this.pageIndex,
       this.vacanT_ID,
       this.localService.getJsonValue('token').data.companyId,
       this.params.controls.name.value? this.params.controls.name.value:'',
       this.params.controls.reclutador.value? this.params.controls.reclutador.value:'',
       this.statusId? this.statusId:'',
       this.progressId? this.progressId:'',
       this.jobId? this.jobId:'',
       this.departmentId? this.departmentId:'',
       this.branchId? this.branchId:'',
       this.range.controls.start.value? this.datepipe.transform(this.range.controls.start.value, 'yyyy-MM-dd'):'',
       this.range.controls.end.value? this.datepipe.transform(this.range.controls.end.value, 'yyyy-MM-dd'):'').subscribe(response => {
          this.dataSource = response.data;
          this.length = response.paginationInformation.totalRows;
       });
  }

  @ViewChild('table') table: MatTable<ClientListCatalog>;

  statusCatalog: ClientListCatalog[];

  displayedColumns: string[] = [
    'fecha',
    'reclutador',
    'candidato',
    'puesto',
    'departamento',
    'sucursal',
    'telefono',
    'correo',
    'cv',
    'estatus',
    'situacion',
    'acciones'
  ];


  openResponseDialog(response: string, message?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);

      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }

  removeSpecialCharactersAndSpaces(text: string): string {
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return text.replace(/[^a-zA-Z0-9]/g, '');
  }

  openDialog(mode:number,message:string){
    let data: string[] = [];

    switch (mode){
      case 0:{
        data.push('Error');
      data.push(message);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
      return dialogRef;
      };
      case 1:{
        data.push('Exito');
      data.push(message);

      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
      return dialogRef;
      };
      case 2:{
        data.push('loading');
        data.push(message);

        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          /* height: '400px', */
          data: data,
        });
        return dialogRef;
      };
      case 3:{

        var dummy = document.createElement('input');


        document.body.appendChild(dummy);
        const userToken = this.localService.getJsonValue('token');
        const companyName = this.removeSpecialCharactersAndSpaces(userToken.data.companyName);
        const urlWithToken = environment.candidateUrl + companyName + '/CD?id=' + message;
        dummy.value = urlWithToken;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);


        data.push('Exito');
        data.push('El token temporal del usuario ha sido copiado al portapapeles');

        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          /* height: '400px', */
          data: data,
        });
        return dialogRef;
      };
    }

  }


  getJobs(){
    this.puzzleService.getJobs(this.localService.getJsonValue('token').data.companyId).subscribe(response=>{
      this.jobs = response;
    });
  }

  getDepartmentsOrdered(){
    this.puzzleService.getDepartmentsOrdered(this.localService.getJsonValue('token').data.companyId).subscribe(response=>{
      this.departments = response;
    });
  }

  getBranchsOrdered(){
    this.puzzleService.getBranchsOrdered(this.localService.getJsonValue('token').data.companyId).subscribe(response=>{
      this.branchs = response;
    });
  }

}

