import { Component, ContentChild, Input, OnInit, Output, TemplateRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dashboard-tooltip',
  templateUrl: './dashboard-tooltip.component.html',
  styleUrls: ['./dashboard-tooltip.component.css']
})
export class DashboardTooltipComponent implements OnInit {

  @Input() open: boolean = false;
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.open = false;
    // avisar al padre que se cerro
    this.closed.emit();
  }
}
