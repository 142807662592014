import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocalService } from 'src/app/services/local.service';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {
  @Input() appPermission: string | string[];
  private userPermissions: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private localService: LocalService,
  ) { }

  ngOnInit(): void {
    const tokencito = this.localService.getJsonValue('token');
    if (tokencito && tokencito.data && tokencito.data.permissions) {
      this.userPermissions = tokencito.data.permissions.map(permission => permission.code);
    } else {
      this.userPermissions = [];
    }

    if (typeof this.appPermission === 'string') {
      const hasPermission = this.userPermissions.includes(this.appPermission);
      if (hasPermission) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    } else {
      const hasPermission = this.appPermission.some(permission => this.userPermissions.includes(permission));
      if (hasPermission) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
  }
}