
<label class="radio-button-container" [class.disabled]="isDisabled">
  <input type="radio"
         [attr.name]="name"
         [value]="value"
         [checked]="value === innerValue"
         (change)="onRadioButtonChange()"
         [disabled]="isDisabled"  class="radio-button-input">
  <div class="radio-button" [class.checked]="value === innerValue"></div>
  <span class="radio-button-label">{{ label }}</span>
</label>
