import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-haibu-banner',
  templateUrl: './haibu-banner.component.html',
  styleUrls: ['./haibu-banner.component.css']
})
export class HaibuBannerComponent implements OnInit {

  defaultPicture: string = '../../assets/icons/icon-512x512.png';

  constructor() { }

  ngOnInit(): void {
  }

  removeSkeleton(event: Event): void {
    const target = event.target as HTMLElement;
    target.parentElement?.classList.add('loaded');
  }

  getDemo() {
    window.open('https://meet.goodtime.io/w/haibuhrcom/jgasteasoro/video-call/intro', '_blank');
  }
}
