import { Component, OnInit } from '@angular/core';
import { PuzzleService } from '../services/puzzle.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LocalService } from '../services/local.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  terms: any;
  currentLang: string;
  haibuIcon: SafeResourceUrl = '../../assets/icons/haibu-icon.svg';

  constructor(
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private translate: TranslateService,)
    {}

  ngOnInit() {
    this.initializeLanguage();
  }

  loadTerms() {
    const appLang = this.currentLang === 'esp' ? 'es_terms' : 'en_terms';
    this.puzzleService.getTerms(appLang).subscribe(data => {
      this.terms = data;
    });
  }


  changeLanguage(lang: string) {
    const newLang = lang === 'es' ? 'esp' : 'eng';
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.loadTerms();
    this.localService.setJsonValue('lang', newLang);
  }

  initializeLanguage() {
    if (this.localService.getJsonValue('lang')) {
      this.translate.use(this.localService.getJsonValue('lang'));
      this.currentLang = this.localService.getJsonValue('lang');
    } else {
      const browserLang = this.translate.getBrowserLang();
      let appLang = this.mapBrowserLangToAppLang(browserLang);
      this.translate.use(appLang);
      this.currentLang = appLang;
      this.localService.setJsonValue('lang', appLang);
    }
    this.loadTerms();
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'en': 'eng',
      'es': 'esp'
    };
    return langMap[browserLang] || 'eng';
  }

}
