import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ResponseSmallDialogComponent } from '../response-small-dialog/response-small-dialog.component';
import { constants } from 'src/app/services/constants';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-multi-photo-dialog',
  templateUrl: './multi-photo-dialog.component.html',
  styleUrls: ['./multi-photo-dialog.component.css']
})
export class MultiPhotoDialogComponent implements OnInit {

  files: any = [];
  title: string = '';
  resultFile: File | null = null;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MultiPhotoDialogComponent>,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    public dialog: MatDialog,
    private ngZone: NgZone
  ) {
    this.files.push(data.file);
    this.title = data.name;
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  fileToUrl(file: Blob) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  addFile(event: any) {
    const file = event.target.files[0];
    const imageExt = ['jpg', 'jpeg', 'gif', 'png', 'webp'];
    let ext = file.name.split('.').pop();
    if (!imageExt.includes(ext.toLowerCase())) {
      let title = this.translate.instant("extErrorNotPermitted")
      let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorNotPermittedTypesImg");
      this.openResultDialog(0, title, message);
      return;
    }

    let fileSize = file.size;
    let maxFileSize = constants.maxFileSize;
    if (fileSize > maxFileSize) {
      let title = this.translate.instant("extErrorFileSize")
      let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorFileSizeMessage");
      this.openResultDialog(0, title, message);
      return;
    }

    this.files.push(event.target.files[0]);
  }

  combineImages() {
    if (this.files.length === 0) {
      alert('No files selected!');
      return;
    }

    if (this.files.length === 1) {
      this.dialogRef.close(this.files[0]);
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      console.error('Failed to get canvas context');
      return;
    }

    this.loading = true;

    // Load all images and calculate the dimensions
    const images = this.files.map(file => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
      });
    });

    Promise.all(images).then(imgs => {
      const imageElements = imgs as HTMLImageElement[];

      // Define the maximum width and height
      const maxWidth = 1080; // or 720
      const maxHeight = 4096; // Adjust as needed
      const marginBottom = 20; // Space between images

      let x = 0;
      let y = 0;
      let rowHeight = 0;

      // Create a temporary canvas to calculate total dimensions
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');

      if (!tempCtx) {
        console.error('Failed to get temporary canvas context');
        this.loading = false;
        return;
      }

      // Load all images to calculate total dimensions
      imageElements.forEach(img => {
        const ratio = img.width / img.height;
        const imgWidth = Math.min(maxWidth, img.width);
        const imgHeight = imgWidth / ratio;

        // Update row height
        rowHeight = Math.max(rowHeight, imgHeight);

        // Check if image fits in current row
        if (x + imgWidth > maxWidth) {
          x = 0;
          y += rowHeight + marginBottom;
          rowHeight = imgHeight;
        }

        // Update temporary canvas dimensions
        tempCanvas.width = Math.max(maxWidth, x + imgWidth);
        tempCanvas.height = y + rowHeight + marginBottom;

        x += imgWidth + marginBottom;
      });

      canvas.width = tempCanvas.width;
      canvas.height = tempCanvas.height;

      x = 0;
      y = 0;
      rowHeight = 0;

      imageElements.forEach(img => {
        const ratio = img.width / img.height;
        const imgWidth = Math.min(maxWidth, img.width);
        const imgHeight = imgWidth / ratio;

        // Update row height
        rowHeight = Math.max(rowHeight, imgHeight);

        // Check if image fits in current row
        if (x + imgWidth > canvas.width) {
          x = 0;
          y += rowHeight + marginBottom;
          rowHeight = imgHeight;
        }

        // Draw image on canvas
        ctx.drawImage(img, x, y, imgWidth, imgHeight);
        x += imgWidth + marginBottom;
      });

      canvas.toBlob(blob => {
        if (blob) {
          // Create a new File object with the JPEG blob
          this.resultFile = new File([blob], 'combined-image.jpg', { type: 'image/jpeg' });
          this.ngZone.run(() => {
            this.dialogRef.close(this.resultFile);
          });
        }
        this.loading = false;
      }, 'image/jpeg', 0.7); // Adjust the quality here (0.7 is 70%)
    }).catch(error => {
      console.error('Failed to combine images', error);
      this.loading = false;
    });
  }

  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
    }
  }
}
