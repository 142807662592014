import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-abc-order-toggle',
  templateUrl: './abc-order-toggle.component.html',
  styleUrls: ['./abc-order-toggle.component.css']
})
export class AbcOrderToggleComponent {

  @Output() toggle = new EventEmitter<void>();

  onToggleOrder() {
    this.toggle.emit();
  }


}
