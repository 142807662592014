import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalService } from '../services/local.service';
import { Token } from '../interfaces/token';
import { PuzzleService } from '../services/puzzle.service';
import { UserLogin } from '../interfaces/user-login';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate/* , CanLoad */ {

  constructor(private router: Router, private localService: LocalService, private puzzleService: PuzzleService, /* public datePipe: DatePipe */) { }

  token: Token;
  accessTokenExpire: Date;
  refreshTokenExpire: Date;
  actualDate: Date = new Date();
  userLogin: UserLogin = {
    UserName: null,
    Password: null,
    RefreshToken: '',
    AccessToken: ''
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.token = this.localService.getJsonValue('token');

    const urlToken = route.queryParams['token'];
    const isResetPasswordRoute = state.url.includes('password-reset');
    if (isResetPasswordRoute && urlToken) {
      return true;
    } else if (isResetPasswordRoute && !urlToken) {
      this.router.navigate(['login']);
      return false;
    }

    if (this.localService.getJsonValue('token')) {
      this.token = this.localService.getJsonValue('token');
      this.refreshTokenExpire = new Date(this.token.data.refreshTokenExpire);
      if (this.actualDate > this.refreshTokenExpire) {
        this.localService.clearToken();
        if (!this.router.url.includes('login')) {
          this.router.navigate(['login'], { queryParams: { processid: route.queryParams.processid } });
        }
        return false;
      } else {
        this.accessTokenExpire = new Date(this.token.data.accessTokenExpire);
        if (this.actualDate > this.accessTokenExpire) {
          this.userLogin.AccessToken = this.token.data.accessToken;
          this.userLogin.RefreshToken = this.token.data.refreshToken;
          this.puzzleService.login(this.userLogin).subscribe(token => {
            this.localService.clearToken();
            this.localService.setJsonValue('token', token)
          })
        }
        return true;
      }
    } else {
      this.localService.clearToken();
      if (!this.router.url.includes('login')) {
        this.router.navigate(['/login'], { queryParams: { processid: route.queryParams.processid } });
      }
      return false;
    }
  }

  // canLoad( route: Route, segments: UrlSegment[]): Observable <boolean> | boolean {

  //     if(this.localService.getJsonValue('token')){

  //     this.token = this.localService.getJsonValue('token');

  //     this.refreshTokenExpire = new Date(this.token.data.refreshTokenExpire);

  //     if( this.actualDate > this.refreshTokenExpire ) {

  //       this.localService.clearToken();

  //       if(!this.router.url.includes('login')){

  //         this.router.navigate(['login']);

  //       }

  //       return false;

  //     } else {

  //       this.accessTokenExpire =  new Date(this.token.data.accessTokenExpire);

  //       // console.log('A esta fecha expira el AccessToken '+this.accessTokenExpire)
  //       // console.log('Estamos a esta fecha '+this.actualDate)

  //       if ( this.actualDate > this.accessTokenExpire ) {
  //         // console.log('Estamos a una mayor fecha')

  //         this.userLogin.AccessToken = this.token.data.accessToken;
  //         this.userLogin.RefreshToken = this.token.data.refreshToken;

  //         this.puzzleService.login(this.userLogin).subscribe( token => {
  //           this.localService.clearToken();
  //           this.localService.setJsonValue('token', token)

  //         });

  //       }
  //       // else console.log('Aun no llegamos a la fecha de exp.')
  //       return true;
  //     }


  //   } else {
  //     this.localService.clearToken();
  //     if(!this.router.url.includes('login')){

  //       this.router.navigate(['login']);

  //     }
  //     return false;
  //   }
  // }
}
