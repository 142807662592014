import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Candidate } from 'src/app/interfaces/Candidate';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-banner-viewer',
  templateUrl: './banner-viewer.component.html',
  styleUrls: ['./banner-viewer.component.css']
})
export class BannerViewerComponent implements OnInit {

  constructor(public sanitizer: DomSanitizer, public dialog: MatDialog, private puzzleService: PuzzleService,private localService: LocalService,private dialogRef: MatDialogRef<BannerViewerComponent>,
    @Inject(MAT_DIALOG_DATA) {data}) {
      this.data = data;
      
 
    }

  data:string="";
   imgsource:string;

  ngOnInit(): void {
    this.imgsource =    this.data  ;

  }

}
