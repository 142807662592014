<div class="modal-user">
  <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>

  <div class="px-3 pb-3 green-title text-center">
    {{ 'createSubdomainTitle' | translate }}
  </div>

  <div class="subtitle-text">
    {{ 'createSubdomainSubtitle' | translate }}
  </div>


  <form autocomplete="off" [formGroup]="subdomainForm" (ngSubmit)="submitSubdomain()">
    <div class="flex flex-wrap -mx-2 pt-2">
      <!-- Subdomain -->
      <div class="w-full px-2 mb-2">
        <div class="flex flex-col">
          <label class="title-input-text" for="subdomain">
            {{ 'createSubdomainLabel' | translate }}
          </label>
          <div class="input-container mobile-responsive">
            <input
              id="subdomain"
              formControlName="subdomain"
              class="custom-input custom-input-large"
              type="text"
              name="subdomain"
              maxlength="100"
              autocapitalize="none"
              (input)="validateSubdomain()"
              (paste)="handlePaste($event)"
            />
            <div class="text-left error-title" *ngIf="subdomainForm.get('subdomain').invalid && (subdomainForm.get('subdomain').dirty || subdomainForm.get('subdomain').touched)">
              <div *ngIf="subdomainForm.get('subdomain').errors.required">
                {{ 'requiredField' | translate }}
              </div>
              <div *ngIf="subdomainForm.get('subdomain').errors.pattern">
                {{ 'invalidSubdomainPattern' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center md:w-auto mt-6">
      <app-custom-primary-button id="addDialogSave" type="submit" size="small" width="120px" height="48px"
        color="primary" [disabled]="sending">
        <span *ngIf="!sending">{{ 'addDialogSave' | translate }}</span>
        <div *ngIf="sending" class="w-full flex justify-center">
          <mat-spinner diameter="20" color="primary"></mat-spinner>
        </div>
      </app-custom-primary-button>
    </div>
  </form>
</div>
