import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalService } from '../local.service';
import { PuzzleService } from '../puzzle.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private localService: LocalService, private puzzleService: PuzzleService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Obtener el token desde el servicio local
    const token = this.localService.getJsonValue('token');

    // Clonar la petición para agregar el token al encabezado Authorization
    if (token && token.data && token.data.accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token.data.accessToken}`
        }
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // Si el error es de autenticación (401), intenta refrescar el token
        if (error.status === 401) {
          return this.handle401Error(req, next);
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Intentar refrescar el token si es un error 401 (no autorizado)
    const token = this.localService.getJsonValue('token');
    if (token && token.data.refreshToken) {
      return this.puzzleService.forceRefreshLogin(token.data.refreshToken, token.data.companyId).pipe(
        switchMap((newToken: any) => {
          if (newToken.isSuccess) {
            this.localService.setJsonValue('token', newToken);

            // Clonar la petición original con el nuevo token
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${newToken.data.accessToken}`
              }
            });
            return next.handle(req);
          } else {
            // Si falla el refresh, redirigir al login
            this.router.navigate(['/login']);
            return throwError(new Error('Unable to refresh token'));
          }
        }),
        catchError(err => {
          this.router.navigate(['/login']);
          return throwError(err);
        })
      );
    } else {
      // Si no hay token de refresh, redirigir al login
      this.router.navigate(['/login']);
      return throwError(new Error('No refresh token available'));
    }
  }
}