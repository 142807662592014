<div class="relative text-center">
  <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="cerrar(false)" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>

  <div class="flex justify-center pb-4">
    <ng-lottie *ngIf="data[0] == 'Exito'" width="90px" height="90px" [options]="options"
      (animationCreated)="animationCreated($event)"></ng-lottie>
    <ng-lottie *ngIf="data[0] == 'Error'" width="90px" height="90px" [options]="options2"
      (animationCreated)="animationCreated($event)"></ng-lottie>
    <ng-lottie *ngIf="data[0] == 'Advertencia'" width="90px" height="90px" [options]="options4"
      (animationCreated)="animationCreated($event)"></ng-lottie>
    <img *ngIf="data[0] == 'loading'" src="/assets/animations/haibu-loading.gif" style="height: 120px; width: 120px;">
  </div>

  <div class="text-lg text-center font-medium" style="color: #06589F;">
    {{data[1]}}
  </div>


  <div *ngIf="data[2]" class="my-1 mt-3 px-5 text-base text-justify font-medium" style="color: #718096;">
    {{data[2]}}
  </div>
  <div *ngIf="data[2]" class="my-1 px-5 pb-3 text-base text-justify font-medium" style="color: #718096;">
    {{data[3]}}
  </div>


  <div class="mt-4">
    <app-custom-primary-button id="confirmBtnIcon" (click)="cerrar(true)" color="primary" size="super-small" class="inline-block">
      {{ 'confirmDialog' | translate }}
    </app-custom-primary-button>
  </div>
</div>
