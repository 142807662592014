import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-input-password',
  templateUrl: './custom-input-password.component.html',
  styleUrls: ['./custom-input-password.component.css']
})
export class CustomInputPasswordComponent  {
  @Input() placeholder: string = '';
  @Input() isError: boolean = false;
  @Input() isMatch: boolean = false;
}
