import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PuzzleService } from '../services/puzzle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { APIResponse } from '../interfaces/response';
import { Gender } from '../interfaces/Gender';
import { Vacancy } from '../interfaces/vacancy';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResponseSmallDialogComponent } from '../dialogs/response-small-dialog/response-small-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Candidate } from '../interfaces/Candidate';
import { DatePipe } from '@angular/common';
import { CandidateDocument } from '../interfaces/CandidateDocument';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CandidateToken } from '../interfaces/CandidateToken';
import { DocumentationTypeInfo } from '../interfaces/DocumentationTypeInfo';
import { CandidateRequest } from '../interfaces/CandidateRequest';
import { Country } from '../interfaces/country';
import { ctR_STATES } from '../interfaces/ctrstate';
import { stS_CITIES } from '../interfaces/stscity';
import { adR_STATE_ID_INFO } from '../interfaces/adr-state-id-info';
import { constants } from '../services/constants';
import { DocuViewerComponent } from '../dialogs/docu-viewer/docu-viewer.component';
import { environment } from 'src/environments/environment.prod';
import { SwUpdate } from '@angular/service-worker';
import { MatSelectChange } from '@angular/material/select';
import { Source } from '../interfaces/source';
import { Meta } from '@angular/platform-browser';
import { LocalService } from 'src/app/services/local.service';
import { ImageCropDialogComponent } from '../dialogs/image-crop-dialog/image-crop-dialog.component';
import { MultiPhotoDialogComponent } from '../dialogs/multi-photo-dialog/multi-photo-dialog.component';

@Component({
  selector: 'app-candidate-external',
  templateUrl: './candidate-external.component.html',
  styleUrls: ['./candidate-external.component.css']
})


export class CandidateExternalComponent implements OnInit {

  @ViewChild('dropdownRef') dropdownRef;
  @ViewChild('fileSelect') fileSelect: ElementRef<HTMLInputElement>;
  selectedInnerDoc: DocumentationTypeInfo;
  innerEvaluations: CandidateDocument[] = [];
  fileloader: HTMLElement;
  isWizardContainerExpanded: boolean = true;
  isWizardContainerExpandedQuestionnaire: boolean = true;
  isWizardContainerExpandedAdress: boolean = false;
  isWizardContainerGeneralOptionalExpanded: boolean = false;
  isWizardContainerDigitalExpedientExpanded: boolean = false;
  isWizardContainerEvaluationsExpanded: boolean = false;
  isTokenValid: boolean | null = null;
  isVacancyInvalid: boolean = false;
  isGenderInvalid: boolean = false;
  isFileInvalid: boolean = false;
  isPhoneInvalid: boolean = false;
  isBirthdateInvalid: boolean = false;
  isBirthdateEmpty: boolean = false;
  isReferenceInvalid: boolean = false;
  companyName: string | null = null;
  jobName: string | null = null;
  sending = false;
  shouldShowFinalAlert: boolean = false;
  loading: MatDialogRef<ResponseSmallDialogComponent>;
  selectedVacancy: Vacancy;
  selectedGender: Gender;
  genders: Gender[];
  selectedSource: string;
  sources: Source[];
  countries: Country;
  states: ctR_STATES[];
  cities: stS_CITIES[];
  states2: ctR_STATES[];
  cities2: stS_CITIES[];
  selectedCity: stS_CITIES;
  selectedState: adR_STATE_ID_INFO;
  selectedBirthCity: stS_CITIES;
  selectedBirthState: adR_STATE_ID_INFO;
  customstatesandcities: boolean = false;
  totalindex = 0;
  termsAccepted = false;
  candidate: Candidate = {
    cdT_ID: '',
    cdT_NAME: '',
    cdT_PATERNAL_SURNAME: '',
    cdT_MATERNAL_SURNAME: '',
    cdT_ACTIVE: false,
    cdT_NUMBER: 0,
    cdT_EMAIL: '',
    cdT_PHONE_NUMBER_ONE: '',
    cdT_PHONE_NUMBER_TWO: '',
    cdT_CURP: '',
    cdT_RFC: '',
    cdT_SOCIAL_SECURITY_NUMBER: '',
    cdT_BIRTH_DATE: '',
    cdT_BIRTH_STATE_CUSTOM: '',
    cdT_BIRTH_CITY_CUSTOM: '',
    cdT_GENDER_ID: '',
    cdT_IMMEDIATE_BOSS: '',
    cdT_COMPANY_ID: '',
    cdT_BRANCH_OFFICE_ID: '',
    cdT_DEPARTMENT_ID: '',
    cdT_JOB_ID: '',
    cdT_STATUS_ID: '',
    cdT_VACANT_ID: '',
    cdT_ADDRESS_ID: '',
    cdT_SALARY_EXPECTED: 0,
    cdT_REFERRED_BY: '',
    cdT_ADDRESS_INFO: {
      adR_CITY_ID: '',
      adR_STATE_ID: '',
      adR_COUNTRY_ID: '',
      adR_STATE_CUSTOM: false,
      adR_CITY_CUSTOM: false,
      adR_STREET: '',
      adR_SUBURB: '',
      adR_CP: '',
      adR_SUITE_NUMBER: '',
      adR_STREET_NUMBER: '',
      adR_COUNTRY_ID_INFO: undefined,
      adR_STATE_ID_INFO: undefined,
      adR_CITY_ID_INFO: undefined
    },
    // el
    cdT_STATUS_CANDIDATE_ID: '',
    cdT_CREATED_DATE: undefined,
    cdT_CREATED_DATE_THIN: undefined,
    cdT_CREATED_BY: '',
    totaL_COUNT: 0,
    totaL_PAGES: 0,
    cdT_DEPARTMENT_INFO: undefined,
    cdT_JOB_INFO: undefined,
    cdT_STATUS_INFO: undefined,
    cdT_STATUS_CANDIDATE_INFO: undefined,
    tkcD_TOKEN_CANDIDATE_DOCUMENT_INFO: new CandidateToken,
    cv: undefined,
    candidateCommentsSummary: undefined,
    cdT_PROFESSIONAL_CERTIFICATE: '',
    cdT_INFONAVIT_CREDIT: 0
  };
  noPermittedFiles = false;
  noPermittedFilesArray = [];
  formattedSalary: string = '';
  actualSalary: number = 0;
  innerDocuments: CandidateDocument[] = [];
  documentsFormsList = [];
  currentLang: string;
  companyId: string;
  vacancyId: string;
  questionaire: any;
  backupID: string;
  isButtonDisabled = false;
  submitted = false;
  innerDocs: DocumentationTypeInfo[];
  showdocuments = true;
  filteredInnerDocs: DocumentationTypeInfo[] = [];
  isFormAlreadySubmitted: boolean = false;
  emailForm: FormGroup;
  logoImage: SafeResourceUrl = '';
  ogLogoImage: string = '';
  allDocumentsUploaded = false;
  isDropdownVisible: boolean = true;
  maxDate = new Date();
  imageFile;
  userImage;
  studyLevels: any;
  yearsOfExperience: any;
  selectedStudyLevel: any;
  selectedYearsOfExperience: any;
  isStudyLevelInvalid: boolean = false;
  isYearsOfExperienceInvalid: boolean = false;
  expandIcon: SafeResourceUrl = '../../assets/icons/expand-more-icon.svg';
  expedientImage: SafeResourceUrl = '../../assets/icons/expedient-not-found-icon.svg';
  deleteIcon: SafeResourceUrl = '../../assets/icons/delete-orange-icon.svg';
  fileIcon: SafeResourceUrl = '../../assets/icons/file-icon.svg';
  haibuBroken: SafeResourceUrl = '../../assets/animations/haibu-broken.gif';
  haibuCheck: SafeResourceUrl = '../../assets/animations/haibu-complete.gif';
  haibuLoading: SafeResourceUrl = '../../assets/animations/haibu-loading.gif';
  haibuLogo: SafeResourceUrl = '../../assets/img/logo.png';
  defaultpfp: SafeResourceUrl = '../../../assets/icons/default-pfp-icon.svg';
  cameraIcon: SafeResourceUrl = '../../../assets/icons/camera-icon.svg';
  @ViewChild('datePicker') datePicker: any;
  @ViewChild('inputFileLogo') inputFileLogo: ElementRef;

  constructor(
    private puzzleService: PuzzleService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private translate: TranslateService,
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer,
    private swUpdate: SwUpdate,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private meta: Meta,
    private localService: LocalService,
    private renderer: Renderer2
  ) {
    this.swUpdate.checkForUpdate();
    try {
      const token = this.activatedRoute.snapshot.queryParamMap.get('id');
      this.validateToken(token);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit(): void {
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 10);
    this.initializeLanguage();
    this.getGenders();
    this.getCountries();
    this.getStudyLevels();
    this.getYearsOfExperience();
  }

  getStudyLevels() {
    this.puzzleService.getCandidateEducationalLevel().subscribe((response) => {
      this.studyLevels = response;
    });
  }

  getYearsOfExperience() {
    this.puzzleService.getCandidateExperienceYears().subscribe((response) => {
      this.yearsOfExperience = response;
    });
  }

  readURL(event): void {
    if (event.target.files && event.target.files[0]) {
      // abrir dialogo de crop
      const dialogRef = this.dialog.open(ImageCropDialogComponent, {
        width: '500px',
        maxHeight: '90vh',
        data: {
          event: event
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.inputFileLogo.nativeElement.value = '';
        if (result) {
          this.imageFile = this.base64toFile(result, 'profilePicture.png');
          this.userImage = this.sanitizer.bypassSecurityTrustResourceUrl(result);
        }
      });
    }
  }

  removeSpecialCharactersAndSpaces(text: string): string {
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return text.replace(/[^a-zA-Z0-9]/g, '');
  }

  validateToken(token: string): void {
    this.puzzleService.validateVacancyToken(token).subscribe((response: APIResponse) => {
      if (response.isSuccess) {
        this.isTokenValid = true;
        this.companyName = this.removeSpecialCharactersAndSpaces(response.data.companyName);
        this.jobName = response.data.jobName;
        this.companyId = response.data.companyId;
        this.vacancyId = response.data.vacancyId;
        this.checkFormSubmission(response.data.vacancyId);
        this.questionaire = response.data.questionnaire;
        this.backupID = response.data.backupId;
        this.logoImage = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.imageUrl}${response.data.companyLogoRoute}`
        );
        this.ogLogoImage = `${environment.imageUrl}${response.data.companyLogoRoute}`;
        this.setMetaTags();
        this.getInnerDocs(this.companyId);

        if (this.companyName) {
          // Validar si la url tiene el companyName
          const url = window.location.href;
          const hasCompanyName = url.includes(this.companyName);
          if (!hasCompanyName) {
            this.router.navigate([this.companyName + '/CE'], { queryParams: { id: token } });
          }
        }

        this.getSources();
      } else {
        this.isTokenValid = false;
      }
    }, error => {
      console.error('An error occurred while validating token', error);
      this.isTokenValid = false;
    });
  }

  onGenderSelected(event: MatSelectChange) {
    this.selectedGender = event.value;
  }

  onStudyLevelSelected(event: MatSelectChange) {
    this.selectedStudyLevel = event.value;
  }

  onYearsOfExperienceSelected(event: MatSelectChange) {
    this.selectedYearsOfExperience = event.value;
  }

  onSourceSelected(event: MatSelectChange) {
    this.selectedSource = event.value;
  }

  onStateChange(event: MatSelectChange) {
    this.selectedState = event.value;
  }

  onCityMatSelectChange(event: MatSelectChange) {
    this.selectedCity = event.value;
    this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = this.selectedCity.ctY_ID;
  }

  onCityMatSelectChange2(event: MatSelectChange) {
    this.selectedBirthCity = event.value;
    this.candidate.cdT_BIRTH_CITY_ID = this.selectedBirthCity ? this.selectedBirthCity.ctY_ID : null;
  }

  createCandidate(myForm: NgForm) {
    Object.values(myForm.controls).forEach((control) => {
      control.markAllAsTouched();
    });

    this.isButtonDisabled = true;
    this.candidate.cdT_VACANT_ID = this.vacancyId;
    this.isGenderInvalid = !this.selectedGender;
    this.isFileInvalid = this.innerDocuments.length <= 0;
    this.isPhoneInvalid = !this.candidate.cdT_PHONE_NUMBER_ONE || this.candidate.cdT_PHONE_NUMBER_ONE?.length < 10;
    this.isReferenceInvalid = !this.selectedSource
    this.isStudyLevelInvalid = !this.selectedStudyLevel;
    this.isYearsOfExperienceInvalid = !this.selectedYearsOfExperience;
    this.isBirthdateEmpty = !this.candidate.cdT_BIRTH_DATE;
    const questionnaireInvalid = !this.checkIfAllQuestionnaireFilled();
    // Validar si la fecha de nacimiento es mayor a la fecha actual y no es más de 100 años
    if (this.candidate.cdT_BIRTH_DATE) {
      const currentDate = new Date();
      const birthDate = new Date(this.candidate.cdT_BIRTH_DATE);
      const difference = currentDate.getFullYear() - birthDate.getFullYear();
      if (difference > 100 || birthDate > currentDate) {
        this.isBirthdateInvalid = true;
      }
    }
    let cvDocument = this.innerDocuments.find(doc => doc.cdoC_DOCUMENT_NAME === 'CV');

    // Verificar si el companyId es "a9240b0e-24ab-4df5-a631-a0948d152d09"
    const isCvOptionalCompany = this.companyId === 'a9240b0e-24ab-4df5-a631-a0948d152d09';

    // Si el companyId es diferente al requerido y no se ha subido el CV, mostrar mensaje de error
    if (!cvDocument && !isCvOptionalCompany) {
        const cvDocumentMissingMessage = this.translate.instant('candidateExternalCVIsRequired');
        this.openResultDialog(0, cvDocumentMissingMessage);
        this.isButtonDisabled = false;
        return;
    }

    // Actualizamos isFileInvalid solo si no es la empresa que permite que el CV sea opcional
    this.isFileInvalid = this.innerDocuments.length <= 0 && !isCvOptionalCompany;

    // Validar fecha de nacimiento
    if (this.isBirthdateInvalid) {
        const birthdateInvalidMessage = this.translate.instant('candidateBirthdateInvalid');
        this.openResultDialog(0, birthdateInvalidMessage);
        this.isButtonDisabled = false;
        return;
    }

    // Validar los demás campos del formulario
    if (!myForm.valid || this.isGenderInvalid || this.isFileInvalid || this.isPhoneInvalid || this.isReferenceInvalid || this.isStudyLevelInvalid || this.isYearsOfExperienceInvalid || questionnaireInvalid || this.isBirthdateEmpty) {

      if (!this.termsAccepted && !this.isGenderInvalid && !this.isFileInvalid && !this.isReferenceInvalid && !this.isStudyLevelInvalid && !this.isYearsOfExperienceInvalid && !questionnaireInvalid && !this.isBirthdateEmpty) {

        if (this.isPhoneInvalid) {
          const phoneLengthMessage = this.translate.instant('candidatePhoneLength');
          this.openResultDialog(0, phoneLengthMessage);
          this.isButtonDisabled = false;
          return;
        }

        const termsNotAcceptedMessage = this.translate.instant('candidateExternalTerms');
        this.openResultDialog(0, termsNotAcceptedMessage);
        this.isButtonDisabled = false;
        return;
      }

      const obligatoryFieldsMessage = this.translate.instant('candidateDetailAlert');
      this.openResultDialog(0, obligatoryFieldsMessage);
      this.isButtonDisabled = false;
      return;
    }  else {
      this.sending = true;
      this.shouldShowFinalAlert = true;
      this.loading = this.openResultDialog(
        2,
        this.translate.instant('candidateDetailAlertLoading')
      );

      if (
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID != null &&
        this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID != null
      ) {
        this.candidate.cdT_ADDRESS_INFO.adR_STATE_CUSTOM = null;
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM = null;
      }
      if (
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_CUSTOM != null &&
        this.candidate.cdT_ADDRESS_INFO.adR_STATE_CUSTOM != null
      ) {
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
        this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID = null;
      }

      if (this.selectedVacancy && this.selectedVacancy.vacanT_ID) {
        this.candidate.cdT_VACANT_ID = this.selectedVacancy.vacanT_ID;
      }

      if (
        this.candidate.cdT_COMPANY_ID == null ||
        this.candidate.cdT_COMPANY_ID.trim() == ''
      ) {
      }

      let dob = this.datepipe.transform(
        this.candidate.cdT_BIRTH_DATE,
        'MM/dd/yyyy'
      );


      this.candidate.cdT_BIRTH_DATE = dob;
      this.loading.close();
      const token = this.activatedRoute.snapshot.queryParamMap.get('id');
      if (token) {
        if (this.selectedSource == '0' || this.selectedSource == '1') {
          this.selectedSource = null;
        }
        const candidateRequest: CandidateRequest = {
          Token: this.activatedRoute.snapshot.queryParamMap.get('id'),
          CDT_NAME: this.candidate.cdT_NAME,
          CDT_PATERNAL_SURNAME: this.candidate.cdT_PATERNAL_SURNAME,
          CDT_MATERNAL_SURNAME: this.candidate.cdT_MATERNAL_SURNAME,
          CDT_EMAIL: this.candidate.cdT_EMAIL,
          CDT_PHONE_NUMBER_ONE: this.candidate.cdT_PHONE_NUMBER_ONE,
          CDT_PHONE_NUMBER_TWO: this.candidate.cdT_PHONE_NUMBER_TWO,
          CDT_GENDER_ID: this.selectedGender.toString(),
          CDT_BIRTH_DATE: this.candidate.cdT_BIRTH_DATE,
          CDT_BIRTH_STATE_ID: this.selectedBirthState ? this.selectedBirthState.stS_ID : null,
          CDT_BIRTH_STATE_CUSTOM: this.candidate.cdT_BIRTH_STATE_CUSTOM,
          CDT_BIRTH_CITY_ID: this.selectedBirthCity ? this.selectedBirthCity.ctY_ID : null,
          CDT_CURP: this.candidate.cdT_CURP,
          CDT_RFC: this.candidate.cdT_RFC,
          CDT_SOCIAL_SECURITY_NUMBER: this.candidate.cdT_SOCIAL_SECURITY_NUMBER,
          CDT_INFONAVIT_CREDIT: this.candidate.cdT_INFONAVIT_CREDIT,
          CDT_PROFESSIONAL_CERTIFICATE: this.candidate.cdT_PROFESSIONAL_CERTIFICATE,
          cdT_SALARY_EXPECTED: this.actualSalary,
          cdT_REFERRED_BY: this.candidate.cdT_REFERRED_BY,
          CDT_PORTFOLIO: this.candidate.cdT_PORTFOLIO,
          CDT_RECRUITMENT_SOURCE_ID: this.selectedSource,
          CDT_OTHER_REFERRED: this.candidate.cdT_OTHER_REFERRED,
          CDT_ADDRESS_INFO: {
            ADR_STATE_ID: this.selectedState ? this.selectedState.stS_ID : null,
            ADR_CITY_ID: this.selectedCity ? this.selectedCity.ctY_ID : null,
            ADR_STREET: this.candidate.cdT_ADDRESS_INFO.adR_STREET,
            ADR_SUBURB: this.candidate.cdT_ADDRESS_INFO.adR_SUBURB,
            ADR_STREET_NUMBER: this.candidate.cdT_ADDRESS_INFO.adR_STREET_NUMBER,
            ADR_SUITE_NUMBER: this.candidate.cdT_ADDRESS_INFO.adR_SUITE_NUMBER,
            ADR_CP: this.candidate.cdT_ADDRESS_INFO.adR_CP
          },
          CDT_CANDIDATE_SCHOLARSHIP_INFO: {
            SCH_ID: this.selectedStudyLevel
          },
          CDT_CANDIDATE_YEARS_EXPERIENCE_INFO: {
            EXP_ID: this.selectedYearsOfExperience
          }
        };
        this.puzzleService.createCandidateWithExternalToken(token, candidateRequest).subscribe(
          async (response) => {
            if (response.isSuccess == true) {
              const candidateId = response.data.candidateID;
              // Espera 1 segundo para asegurar que el candidato esté completamente registrado.
              setTimeout(async () => {
                //subir respuestas de cuestionario
                if (this.questionaire) {
                  const responses = {
                    CANDIDATE_ID: candidateId,
                    BACKUP_ID: this.backupID,
                    RESPONSES: this.questionaire.questions.map(question => {
                      if (question.questioN_TYPE == 'OPEN_ENDED') {
                        return {
                          QUESTION_ID: question.questioN_ID,
                          ANSWER_TEXT: question.response
                        };
                      } else if (question.questioN_TYPE == 'YES_NO' || question.questioN_TYPE == 'MULTIPLE_CHOICE') {
                        return {
                          QUESTION_ID: question.questioN_ID,
                          SELECTED_OPTION_IDS: [question.response]
                        };
                      } else if (question.questioN_TYPE == 'CHECKBOXES') {
                        return {
                          QUESTION_ID: question.questioN_ID,
                          SELECTED_OPTION_IDS: question.response
                        };
                      }
                    })
                  }
                  await this.puzzleService.saveCandidateResponses(responses).toPromise();
                }
                // subir foto
                if (this.userImage) {
                  const formData = new FormData();
                  formData.append('image.file', this.imageFile, this.imageFile.name);
                  formData.append('candidateId', candidateId);
                  await this.puzzleService.createCandidateExternalImage(formData).toPromise();
                }
                // subir documentos
                if (this.innerDocuments.length > 0) {
                  const uploadSuccess = await this.sendDocs(candidateId);
                  this.loading.close();
                  if (this.noPermittedFiles) {
                    this.noPermittedFiles = false;
                    this.loading.close();
                    let title = this.translate.instant("extErrorNotPermitted")
                    let message = "<br>" + this.noPermittedFilesArray.join("<br>") + "<br><br>" + this.translate.instant("extErrorNotPermittedTypes");
                    this.noPermittedFilesArray = []
                    this.openResultDialog(0, title, message);
                    return;
                  }
                  if (uploadSuccess) {
                    localStorage.setItem(this.vacancyId, 'true');
                    this.translate.get('candidateExtenralSuccessMessage').subscribe((translatedText: string) => {
                      this.openResultDialog(1, translatedText);
                    });
                    this.checkFormSubmission(this.vacancyId);
                  } else {
                    this.translate.get('candidateExternalError').subscribe((translatedText: string) => {
                      this.openResultDialog(0, translatedText);
                    });
                  }
                } else {
                  this.loading.close();
                  localStorage.setItem(this.vacancyId, 'true');
                  this.translate.get('candidateExtenralSuccessMessage').subscribe((translatedText: string) => {
                    this.openResultDialog(1, translatedText);
                  });
                  this.checkFormSubmission(this.vacancyId);
                }
              }, 1000);
            } else {
              this.sending = false;
              this.loading.close();
              const translatedErrorMessage = this.translate.instant(response.message);
              this.openResultDialog(0, translatedErrorMessage);
              this.isButtonDisabled = false;
            }
          },
          error => {
            this.sending = false;
            this.loading.close();
            this.translate.get('candidateExternalError').subscribe((translatedText: string) => {
              this.openResultDialog(0, translatedText);
            });
            this.isButtonDisabled = false;
          }
        );
      }
    }
  }

  async sendDocs(candidateId: string): Promise<boolean> {
    if (this.innerDocuments.length === 0) {
      this.translate.get('candidateDocumentsUploadNoChoose').subscribe((translatedText: string) => {
        this.openResultDialog(0, translatedText);
      });
      return false;
    }

    this.loading = this.openResultDialog(2, 'Cargando...');

    let success = true;
    let responseList: string[] = [];

    for (const form of this.documentsFormsList) {
      form.append('companyId', this.candidate.cdT_COMPANY_ID);
      form.append('candidateId', candidateId);
      form.append('cdOC_RECRUITER_ACTIVE', true);

      try {
        const response = await this.puzzleService.sendDocsExternal(form, false).toPromise();
        if (response.isSuccess) {
          responseList.push(form.get('name') + ' - Éxito');
        } else {
          success = false;
          responseList.push(form.get('name') + ' - Falló');
          if (response.message.includes('extensión no permitida')) {
            this.noPermittedFiles = true;
            this.noPermittedFilesArray.push(form.get('name'));
          }
        }
      } catch (error) {
        success = false;
        responseList.push(form.get('name') + ' - Falló');
      }
    }

    this.loading.close();
    return success;
  }

  checkFormSubmission(id: string) {
    this.isFormAlreadySubmitted = localStorage.getItem(id) === 'true';
  }

  getGenders() {
    this.puzzleService.getGenders(false).subscribe((response) => {
      this.genders = response;
    });
  }

  getSources() {
    try {
      this.puzzleService.getSourcesExt(100, 0, this.companyId).subscribe((response) => {
        this.sources = response;
      });
    } catch {
      console.error('Error al obtener las fuentes');
    }
  }

  getCountries() {
    this.puzzleService.getCountryDemo().subscribe((countries) => {
      this.countries = countries;
      this.states = this.countries.ctR_STATES;
      this.states2 = this.countries.ctR_STATES;
    });
  }

  getInnerDocs(companyId: string) {
    this.puzzleService.getInnerDocumentsExternal(companyId).subscribe((response) => {
      this.innerDocs = response.map(doc => ({ ...doc, pdT_VISIBLE: true }));
      this.updateFilteredInnerDocs();
      this.checkDocumentUploadStatus(); // Llama a checkDocumentUploadStatus aquí
    });
  }

  updateFilteredInnerDocs() {
    if (!this.innerDocuments) {
      this.innerDocuments = [];
    }
    if (this.innerDocs) {
      this.filteredInnerDocs = this.innerDocs.filter(outerDoc =>
        !this.innerDocuments.some(innerDoc =>
          innerDoc.cdoC_DOCUMENT_NAME === outerDoc.pdT_NAME && !innerDoc.cdoC_LINK_ACTIVE
        )
      );
    } else {
      console.error('innerDocs es indefinido!');
    }
  }

  get visibleInnerDocs() {
    if (!this.innerDocs) {
      return [];
    }
    return this.innerDocs.filter(doc => doc.cdoC_LINK_ACTIVE === true);
  }

  checkDocumentUploadStatus(): void {
    if (this.innerDocs) {
      const visibleDocumentsCount = this.innerDocs.filter(doc => doc.cdoC_LINK_ACTIVE === true).length;
      this.isDropdownVisible = visibleDocumentsCount > 0;
    } else {
      console.error('innerDocs es indefinido!');
    }
  }


  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
    }
  }

  showSuccessDialog(candidateId) {
    this.sending = false;
    this.loading.close();
    if (this.shouldShowFinalAlert) {
      this.loading.close();
      this.openResultDialog(1, this.translate.instant('candidateDetailAlertRegister'));
    }
  }

  changeLanguage(lang: string): void {
    const newLang = lang;
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.localService.setJsonValue('lang', newLang);
    this.datePicker.changeLang(newLang);
  }

  initializeLanguage() {
    if (this.localService.getJsonValue('lang')) {
      this.translate.use(this.localService.getJsonValue('lang'));
      this.currentLang = this.localService.getJsonValue('lang');
    } else {
      const browserLang = this.translate.getBrowserLang();
      let appLang = this.mapBrowserLangToAppLang(browserLang);
      this.translate.use(appLang);
      this.currentLang = appLang;
      this.localService.setJsonValue('lang', appLang);
    }
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'en': 'eng',
      'es': 'esp'
    };
    return langMap[browserLang] || 'eng';
  }

  onTab(event: KeyboardEvent, nextElementId: string) {
    event.preventDefault();
    const nextElement = document.getElementById(nextElementId);
    if (nextElement) {
      nextElement.focus();
    }
  }

  resetFileInput() {
    if (this.fileSelect && this.fileSelect.nativeElement) {
      this.fileSelect.nativeElement.value = '';
    }
  }

  trackByFn(index, item) {
    return item.gndR_ID;
  }

  trackByStateFn(index, item) {
    return item.stS_ID;
  }

  trackByBirthStateFn(index, item) {
    return item.stS_ID;
  }
  trackByCityFn2(index, item) {
    return item.ctY_ID;
  }

  toggleWizardContainer() {
    this.isWizardContainerExpanded = !this.isWizardContainerExpanded;
  }

  toggleWizardContainerQuestionnaire() {
    this.isWizardContainerExpandedQuestionnaire = !this.isWizardContainerExpandedQuestionnaire;
  }

  toggleWizardContainerAdress() {
    this.isWizardContainerExpandedAdress = !this.isWizardContainerExpandedAdress;
  }

  toggleWizardContainerGeneralOptional() {
    this.isWizardContainerGeneralOptionalExpanded = !this.isWizardContainerGeneralOptionalExpanded;
  }

  toogleWizardContainerExpedient() {
    this.isWizardContainerDigitalExpedientExpanded = !this.isWizardContainerDigitalExpedientExpanded;
  }

  toogleWizardContainerEvaluations() {
    this.isWizardContainerEvaluationsExpanded = !this.isWizardContainerEvaluationsExpanded;
  }

  onStateMatSelectChange(event: MatSelectChange) {
    const newSelection: adR_STATE_ID_INFO = event.value;
    this.selectedState = newSelection;

    this.candidate.cdT_ADDRESS_INFO.adR_STATE_ID = newSelection.stS_ID;

    if (newSelection && newSelection.stS_CITIES) {
      this.cities = newSelection.stS_CITIES;

      if (this.candidate.cdT_ADDRESS_INFO && this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID) {
        this.selectedCity = this.cities.find(city => city.ctY_ID === this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID);
      } else {
        this.selectedCity = null;
        this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
      }
    } else {
      this.cities = [];
      this.selectedCity = null;
      this.candidate.cdT_ADDRESS_INFO.adR_CITY_ID = null;
    }
  }

  onStateMatSelectChange2(event: MatSelectChange) {
    const newSelection: adR_STATE_ID_INFO = event.value; // El estado seleccionado
    this.selectedBirthState = newSelection;
    this.candidate.cdT_BIRTH_STATE_ID = newSelection.stS_ID;

    this.cities2 = newSelection.stS_CITIES || [];

    if (this.candidate.cdT_BIRTH_CITY_ID) {
      this.selectedBirthCity = this.cities2.find(city => city.ctY_ID === this.candidate.cdT_BIRTH_CITY_ID);
    } else {
      this.selectedBirthCity = null;
      this.candidate.cdT_BIRTH_CITY_ID = null;
    }
  }

  loadStates(id: number) {
    if (this.countries.ctR_CUSTOM_INFO_ACTIVE == true) {
      this.customstatesandcities = true;
    } else {
      this.customstatesandcities = false;
      this.states = this.countries.ctR_STATES;
    }
  }

  loadCities(id: number) {
    this.cities = this.states[id].stS_CITIES;
  }

  loadCities2(id: number) {
    this.cities2 = this.states2[id].stS_CITIES;
  }

  loadcitiesid(id) {
    for (let i = 0; i < this.states.length; i++) {
      if (this.states[i].stS_ID == id) {
        this.cities = this.states[i].stS_CITIES;
        break;
      }
    }
  }

  loadcitiesid2(id) {
    for (let i = 0; i < this.states2.length; i++) {
      if (this.states2[i].stS_ID == id) {
        this.cities2 = this.states2[i].stS_CITIES;
        break;
      }
    }
  }

  deletedoc(thedoc: CandidateDocument, mode) {
    event.stopPropagation();
    const confirmTitle = this.translate.instant('candidateDeleteDocumentTitle');
    const confirmMessage = this.translate.instant('candidateDeleteDocumentMessage');
    const confirmDialog = this.openResultDialog(0, confirmMessage, confirmTitle);

    confirmDialog.afterClosed().subscribe(result => {
      if (thedoc.cdoC_ID != null) {
        const loadingMessage = this.translate.instant('candidateDocumentsWait');
        let loading = this.openResultDialog(2, loadingMessage);

        thedoc.cdoC_ACTIVE = false;
      } else {
        let indextopop = [];

        if (mode == 0) {
          for (let i = 0; i < this.innerDocuments.length; i++) {
            if (
              this.innerDocuments[i].cdoC_DOCUMENT_NAME ==
              thedoc.cdoC_DOCUMENT_NAME
            ) {
              indextopop.push(i);
              break;
            }
          }

          for (let i = 0; i < indextopop.length; i++) {
            this.innerDocuments.splice(indextopop[i], 1);
          }

          for (let i = 0; i < this.innerDocs.length; i++) {
            if (this.innerDocs[i].pdT_NAME == thedoc.cdoC_DOCUMENT_NAME) {
              this.innerDocs[i].pdT_VISIBLE = true;
              this.innerDocs[i].cdoC_LINK_ACTIVE = true;
            }
          }
        }
        this.documentsFormsList.splice(thedoc.cdoC_INDEX, 1);
        this.selectedInnerDoc = null;
        this.fileloader = null;
        this.checkDocumentUploadStatus();
        this.updateFilteredInnerDocs();
        this.checkDocumentUploadStatus();
      }
    });
  }

  updateInnerDocsVisibility() {
    if (this.innerDocs && this.innerDocs.length > 0) {
      for (let i = 0; i < this.innerDocuments.length; i++) {
        for (let e = 0; e < this.innerDocs.length; e++) {
          if (this.innerDocs[e].pdT_NAME == this.innerDocuments[i].cdoC_DOCUMENT_NAME) {
            this.innerDocs[e].pdT_VISIBLE = false;
            this.innerDocs[e].cdoC_LINK_ACTIVE = false;
          }
        }
      }
    }
  }

  fileChangeEvent(event, mode) {

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {

      let docToAdd = {} as CandidateDocument;

      let file: File = fileList[0];

      // revisar si la ext del archivo es permitida
      // '.jpg', '.jpeg', '.gif', '.png', '.webp', '.doc', '.docx', '.pdf', '.csv', '.xls', and '.xlsx'.
      let ext = file.name.split('.').pop();
      let allowedExts = constants.allowedExt;
      if (!allowedExts.includes(ext.toLowerCase())) {
        let title = this.translate.instant("extErrorNotPermitted")
        let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorNotPermittedTypes");
        this.openResultDialog(0, title, message);
        return;
      }

      let fileSize = file.size;
      let maxFileSize = constants.maxFileSize;
      if (fileSize > maxFileSize) {
        let title = this.translate.instant("extErrorFileSize")
        let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorFileSizeMessage");
        this.openResultDialog(0, title, message);
        return;
      }

      if (mode == 0) {
        // revisar si es imagen
        const imageExt = ['jpg', 'jpeg', 'gif', 'png', 'webp'];
        let isImage = imageExt.includes(ext.toLowerCase());
        if (isImage) {
          let dialog = this.dialog.open(MultiPhotoDialogComponent, {
            width: '600px',
            maxWidth: '90vw',
            maxHeight: '90vh',
            panelClass: 'compact-dialog',
            data: {
              name: this.selectedInnerDoc.pdT_NAME,
              file: file,
            },
          });

          dialog.afterClosed().subscribe((result: File) => {
            if (result) {
              file = result;

              let fileSize = file.size;
              let maxFileSize = constants.maxFileSize;
              if (fileSize > maxFileSize) {
                let title = this.translate.instant("extErrorFileSize")
                let message = "<br>" + file.name + "<br><br>" + this.translate.instant("extErrorFileSizeMessage");
                this.openResultDialog(0, title, message);
                return;
              }

              this.processFile(file);
            }
          });
          return;
        }

        let index = this.totalindex;
        this.totalindex++;

        docToAdd.cdoC_DOCUMENT_NAME = this.selectedInnerDoc.pdT_NAME;
        docToAdd.cdoC_INDEX = index;
        this.innerDocuments.push(docToAdd);

        let thisfrom = new FormData();

        thisfrom.append('cdOC_DOCUMENT_TYPE_ID', constants.candidateDocumentId);
        thisfrom.append(
          'cdOC_PERSONAL_DOCUMENTATION_TYPE_ID',
          this.selectedInnerDoc.pdT_ID
        );
        thisfrom.append('fileWrapper.file', file, file.name);
        thisfrom.append('name', this.selectedInnerDoc.pdT_NAME);

        this.documentsFormsList.push(thisfrom);

        for (let i = 0; i < this.innerDocs.length; i++) {
          if (this.innerDocs[i].pdT_ID == this.selectedInnerDoc.pdT_ID) {
            this.innerDocs[i].pdT_VISIBLE = false;
            this.innerDocs[i].cdoC_LINK_ACTIVE = false;
          }
        }
        this.updateFilteredInnerDocs();
      }
    }
    this.checkDocumentUploadStatus();

    this.selectedInnerDoc = null;
  }

  processFile(file: File) {
    let index = this.totalindex;
    this.totalindex++;
    let docToAdd = {} as CandidateDocument;

    docToAdd.cdoC_DOCUMENT_NAME = this.selectedInnerDoc.pdT_NAME;
    docToAdd.cdoC_INDEX = index;
    docToAdd.originalName = file.name;

    this.innerDocuments.push(docToAdd);

    let thisfrom = new FormData();
    thisfrom.append('cdOC_DOCUMENT_TYPE_ID', constants.candidateDocumentId);
    thisfrom.append('cdOC_PERSONAL_DOCUMENTATION_TYPE_ID', this.selectedInnerDoc.pdT_ID);
    thisfrom.append('fileWrapper.file', file, file.name);
    thisfrom.append('name', this.selectedInnerDoc.pdT_NAME);

    this.documentsFormsList.push(thisfrom);

    for (let i = 0; i < this.innerDocs.length; i++) {
      if (this.innerDocs[i].pdT_ID == this.selectedInnerDoc.pdT_ID) {
        this.innerDocs[i].pdT_VISIBLE = false;
        this.innerDocs[i].cdoC_LINK_ACTIVE = false;
      }
    }

    this.selectedInnerDoc = null;
  }

  onAddFileKeyPress(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.fileSelectClick();
    }
  }

  fileSelectClick() {

    event.preventDefault();
    event.stopPropagation();
    if (this.fileloader == null) {
      this.fileloader = document.getElementById('fileSelect');
    }

    if (this.selectedInnerDoc != null) {
      this.fileloader.click();
    } else {
      const translatedMessage = this.translate.instant('candidateDetailAlert');
      this.openResultDialog(0, translatedMessage);
    }
  }

  opendoc(route: string) {
    if (route != null) {
      let dialog = this.dialog.open(DocuViewerComponent, {
        panelClass: 'custom-dialog-cv',
        maxWidth: '100vw !important',
        data: { data: route, header: 'Curriculum vitáe' },
      });

      dialog.afterClosed().subscribe((result) => { });
    }
  }

  handleKeydownQuestionnaire(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.toggleWizardContainerQuestionnaire();
    }
  }

  handleKeydownAdress(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.toggleWizardContainerAdress();
    }
  }

  handleKeydownGeneralData(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.toggleWizardContainerGeneralOptional();
    }
  }

  handleKeydownContainerExpedient(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.toogleWizardContainerExpedient();
    }
  }

  onSpacePress(event: KeyboardEvent) {
    if (document.activeElement === event.target) {
      this.submitted = true;
      this.isButtonDisabled = true;

    }
    event.preventDefault();
  }

  onEnterPress(event: KeyboardEvent) {
    if (document.activeElement === event.target) {
      this.submitted = true;
      this.isButtonDisabled = true;
    }
    event.preventDefault();
  }

  loadGenderTranslations(genders: any[]) {
    let translations: { [key: string]: string } = {};
    genders.forEach(gender => {
      translations[`gender.${gender.gndR_NAME}`] = gender.translatedName;
    });
    this.translate.setTranslation(this.currentLang, translations, true);
  }

  setMetaTags() {
    // Al parecer hay que habilitar SSR para que funcione bien esto
    this.meta.updateTag({ property: 'og:title', content: this.jobName });
    this.meta.updateTag({ property: 'og:description', content: this.translate.instant('candidateExternalMetaDescription') });
    this.meta.updateTag({ property: 'og:image', content: this.ogLogoImage });
    // Twitter
    this.meta.updateTag({ name: 'twitter:title', content: this.jobName });
    this.meta.updateTag({ name: 'twitter:description', content: this.translate.instant('candidateExternalMetaDescription') });
    this.meta.updateTag({ name: 'twitter:image', content: this.ogLogoImage });
  }

  base64toFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  checkIfAllQuestionnaireFilled() {
    if (this.questionaire && this.questionaire.questions) {
      let error = false;
      for (let i = 0; i < this.questionaire.questions.length; i++) {
        if (typeof this.questionaire.questions[i].response === 'string') {
          this.questionaire.questions[i].response = this.questionaire.questions[i].response.trim();
        }
        if (!this.questionaire.questions[i].response || this.questionaire.questions[i].response == '') {
          this.questionaire.questions[i].invalid = true;
          error = true;
        } else {
          this.questionaire.questions[i].invalid = false;
        }
      }
      if (error) {
        return false;
      }
    }
    return true;
  }

  onSelectionChange(event: any, question: any) {
    if (event.source.selected.length > question.correctOptionCount) {
      event.source.deselect(event.option.value);
    }
  }

  isOptionDisabled(question: any, optionId: any): boolean {
    return question.response && question.response.length >= question.correctOptionCount && !question.response.includes(optionId);
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focus-input');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focus-input');
  }
}
