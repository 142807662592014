import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { ValidatorService } from '../../services/validator.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PuzzleService } from '../../services/puzzle.service';
import { HomeComponent } from '../../home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Status } from '../../interfaces/status';
import { Department } from '../../interfaces/department';
import { TranslateService } from '@ngx-translate/core';
import { CustomTourService } from 'src/app/services/custom-tour.service';

@Component({
  selector: 'app-add-or-edit-department',
  templateUrl: './add-or-edit-department.component.html',
  styleUrls: ['./add-or-edit-department.component.css']
})
export class AddOrEditDepartmentComponent implements OnInit {

  listaDepartamentos: Department[] = [];

  sendDepartment: Department = {
    deP_COMPANY_ID: '',
    deP_NAME: '',
    deP_DESCRIPTION: '',
    deP_ID: '',
  };

  esNuevo: boolean = false;
  tabCount: number = 0;
  sending: boolean = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.tabCount++;
      if (this.tabCount === 2) {
        const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
        if (submitButton) {
          submitButton.focus();
        }
      }
    } else if (event.key !== 'Enter') {
      this.tabCount = 0;
    }
  }

  @HostListener('document:keydown.tab', ['$event'])
  handleTabPress(event: KeyboardEvent) {
    this.tabCount++;
    if (this.tabCount === 2) {
      const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.focus();
      }
    }
  }
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterPress(event: KeyboardEvent) {
    if (this.esNuevo) return;
    if (this.tabCount === 1) {
      event.preventDefault();
      if (!this.esNuevo) {
        this.addDepartment();
      }
    } else if (this.tabCount >= 2) {
      event.preventDefault();
      this.submitForm();
    }
    this.tabCount = 0;
  }

  constructor(
    private elementRef: ElementRef,
    private translate: TranslateService,
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<HomeComponent>,
    private customTourService: CustomTourService,
    @Inject(MAT_DIALOG_DATA) public data: Department | string
  ) { }

  miFormulario: FormGroup = this.fb.group({
    nombre: ['', [this.isRequiredIfEmptyArray.bind(this), this.noSpecialCharactersValidator]],
    descripcion: ['', [this.noSpecialCharactersValidator]]
  });

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/()"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  public isRequiredIfEmptyArray(control: FormControl) {
    const value = control.value || '';

    // Supongo que listaDepartamentos es una propiedad del componente que contiene el array.
    if (this.listaDepartamentos.length === 0 && !value.trim()) {
      return { 'required': true };
    }

    return null;
  }

  ngOnInit(): void {
    if (!(typeof this.data == 'string')) {
      this.esNuevo = true;
      this.miFormulario.reset({
        nombre: this.transformValue(this.data.deP_NAME),
        descripcion: this.transformValue(this.data.deP_DESCRIPTION)
      })
    } else {
      this.miFormulario.get('nombre')?.valueChanges.subscribe(value => {
        const isFilled = (value && value.trim().length > 0) || this.listaDepartamentos.length > 0;
        this.customTourService.nombreFieldStatus$.next(isFilled);
      });
    }
  }

  addDepartment(event?: Event) {
    if (!this.esNuevo) {

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.miFormulario.markAllAsTouched();
      const nombreValue = this.miFormulario.get('nombre').value || '';
      if (nombreValue.trim() !== '' && this.miFormulario.valid) {
        // validar que no exista el nombre en la lista
        const existeNombre = this.listaDepartamentos.find(department => department.deP_NAME === nombreValue);
        if (!existeNombre) {
          const nuevoDepartamento: Department = this.createDepartment();
          this.listaDepartamentos.unshift(nuevoDepartamento);
          this.miFormulario.reset();
        } else {
          this.miFormulario.get('nombre')?.setValue('');
        }
      }
    }
  }

  createDepartment() {
    let departmentId = null;
    if (typeof this.data !== 'string' && this.data.deP_ID) {
      departmentId = this.data.deP_ID;
    }
    return {
      deP_COMPANY_ID: typeof this.data === 'string' ? this.data : this.data.deP_COMPANY_ID,
      deP_NAME: this.miFormulario.get('nombre').value,
      deP_DESCRIPTION: this.miFormulario.get('descripcion').value,
      deP_ID: departmentId,
    };
  }

  async submitForm() {
    this.miFormulario.markAllAsTouched();
    if (this.miFormulario.valid) {
      const nombreValue = this.miFormulario.get('nombre').value || '';
      const descripcionValue = this.miFormulario.get('descripcion').value || '';
      if (nombreValue.trim() !== '') {
        const departamento = {
          ...this.createDepartment(),
          deP_DESCRIPTION: descripcionValue
        };
        if (this.listaDepartamentos.length === 0) {
          this.sentDepartment(departamento);
          return;
        } else {
          this.listaDepartamentos.push(departamento);
        }
      }
      const departamentosParaEnviar = this.listaDepartamentos.map(departamento => ({
        ...departamento,
        esNuevo: !departamento.deP_ID,
        deP_DESCRIPTION: departamento.deP_DESCRIPTION || ''
      }));
      if (departamentosParaEnviar.length > 0) {
        let ultimoNumeroFase = 0;
        let responses = [];
        this.sending = true;

        const promises = departamentosParaEnviar.map(async (department, index) => {
          department.deP_NUMBER = ultimoNumeroFase + 1 + index;

          try {
            const response = await this.puzzleService.createOrEditDepartment(department).toPromise();
            return {
              isSuccess: response.isSuccess,
              message: this.translate.instant(response.message),
              name: department.deP_NAME
            };
          } catch (error) {
            const errorMessage = this.translate.instant('addDepartamentAlert');
            return { isSuccess: false, message: errorMessage, name: department.deP_NAME };
          }
        });

        try {
          responses = await Promise.all(promises);
        } catch (error) {
          console.error("Error en las promesas:", error);
        }

        this.dialogRef.close(responses);
      } /* else {
        this.dialogRef.close([]);
      } */

    }
  }

  sentDepartment(departamento) {
    this.sending = true;
    this.puzzleService.createOrEditDepartment(departamento).subscribe(
      response => {
        const translatedMessage = this.translate.instant(response.message);
        this.dialogRef.close([{ isSuccess: response.isSuccess, message: translatedMessage }]);
      },
      error => {
        const errorMessage = this.translate.instant('addDepartamentAlert');
        this.dialogRef.close([{ isSuccess: false, message: errorMessage }]);
      }
    );
  }

  deleteDepartment(index: number): void {
    this.listaDepartamentos.splice(index, 1);
  }

  invalidField(field: string): boolean {
    const fieldValue = this.miFormulario.get(field)?.value || '';
    if (this.listaDepartamentos.length === 0) {
      return (this.miFormulario.get(field)?.invalid || fieldValue.trim() === '') && this.miFormulario.get(field)?.touched;
    }
    return false;
  }

  closeModal() {
    this.dialogRef.close();
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]/g, '').trimStart();
  }

}
