<!-- <div class = "px-3 pb-6 text-2xl gray" style = "border-bottom: 1px solid #EBEBEB;">Mensaje:</div> -->
<!-- <div *ngIf = "data[0] == 'Error' " class = "text-2xl text-center" style="color: #06589F;">
    {{data[0]}}
</div>
<div *ngIf = "data[0] == 'Exito' " class = "text-2xl text-center" style="color: #06589F;">
    {{data[0]}}
</div> -->

<div class="py-6 text-2xl text-center" style="color: #06589F;">
  {{ message }}
</div>

<div class="flex justify-center">
  <ng-lottie *ngIf="responseType == 'Exito'" width="100px" height="100px" [options]="options"
    (animationCreated)="animationCreated($event)"></ng-lottie>
  <ng-lottie *ngIf="responseType == 'Error'" width="100px" height="100px" [options]="options2"
    (animationCreated)="animationCreated($event)"></ng-lottie>
  <img *ngIf="responseType == 'loading'" src="/assets/animations/haibu-loading.gif" style="height: 250px; width: 250px;">
</div>

<div class="flex flex-col md:flex-row justify-center pt-6 px-2 md:px-0 space-y-4 md:space-y-0 md:space-x-4">
  <!-- Botón adicional a la izquierda -->
  <div *ngIf="showNewButton" class="w-full md:w-auto">
    <app-custom-primary-button id="newButton" (click)="newButtonAction()" color="secondary" size="small">
      {{"seeInHaibuJobs" | translate}}
    </app-custom-primary-button>
  </div>

  <!-- Botón de aceptación a la derecha -->
  <div class="w-full md:w-auto">
    <app-custom-primary-button id="responseAnchorDialogAccept" (click)="cerrar()" color="primary" size="small">
      {{"responseAnchorDialogAccept" | translate}}
    </app-custom-primary-button>
  </div>
</div>
