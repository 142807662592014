import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Job } from 'src/app/interfaces/job';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-add-or-edit-discard-mail',
  templateUrl: './add-or-edit-discard-mail.component.html',
  styleUrls: ['./add-or-edit-discard-mail.component.css']
})
export class AddOrEditDiscardMailComponent implements OnInit {

  sending: boolean = false;
  mostrarDescripcion = false;
  subdomain: string = '';
  compName: string = '';
  compId: string = '';
  cetId: string = '';

  miFormulario: FormGroup = this.fb.group({
    descripcion: ['', [Validators.required]]
  });

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/(),"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  constructor(
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<AddOrEditDiscardMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Job,
  ) { }

  ngOnInit(): void {
    this.miFormulario.reset({
      descripcion: ''
    });

    const urlParams = new URLSearchParams(window.location.search);
    const companyId = urlParams.get('id');
    this.compId = companyId || '';
    if (companyId) {
      this.puzzleService.getTemplateEmailDropSource(companyId).subscribe((data: any) => {
        if (data) {
          this.miFormulario.get('descripcion')?.setValue(data.cetText);
          this.subdomain = data.compSubdomain;
          this.compName = data.compName;
          this.cetId = data.cetId;
        }
      });
    }

    // route params
    this.route.queryParams.subscribe(params => {
      const modal = params['modal'];
      if (modal == '2') {
        this.mostrarDescripcion = true;
      } else {
        this.mostrarDescripcion = false;
      }
    });
  }

  createOrUpdateDiscardMessage() {
    if (this.miFormulario.invalid) {
      this.miFormulario.markAllAsTouched();
      return;
    }

    this.sending = true;
    let data = {}
    if (this.cetId) {
      data = {
        cetText: this.miFormulario.get('descripcion')?.value,
        compId: this.compId,
        cetId: this.cetId
      }
    } else {
      data = {
        cetText: this.miFormulario.get('descripcion')?.value,
        compId: this.compId
      }
    }
    this.puzzleService.createOrUpdateEmailTemplate(data).subscribe((res: any) => {
      if (res) {
        this.dialogRef.close({
          isSuccess: res.isSuccess,
          message: res.message
        });
      }
    });
  }

  toggleDescripcion() {
    this.mostrarDescripcion = !this.mostrarDescripcion;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
