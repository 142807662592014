<div class="sticky w-full flex flex-col md:flex-row background-colorr">
  <div class="flex items-center justify-center w-full md:w-1/5">
    <img class="h-16 w-auto mx-4" [src]="haibuIcon" alt="">
  </div>
  <div class="flex justify-end w-full md:w-4/5 py-8 pr-4">
    <select (change)="changeLanguage($event.target.value)" [value]="currentLang"
      class="block appearance-none bg-white border border-gray-300 hover:border-gray-400 px-2 py-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
      <option value="es" [selected]="currentLang === 'esp'">Español</option>
      <option value="en" [selected]="currentLang === 'eng'">English</option>
    </select>
  </div>
</div>
<div class="py-6 px-6 md:px-20 text-justify">
  <div class="pb-4 flex flex-col items-center justify-center">
    <div class="font-medium text-center text-3xl">
      {{ 'termsTitle' | translate }}
    </div>
  </div>
  <div *ngIf="terms">
    <div [innerHTML]="terms.value"></div>
  </div>
</div>
