import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {
  transform(value: string, lineBreak: boolean = false): string {
    if (!value) return '';

    // Interpretar la fecha como UTC y ajustarla a la hora local
    const date = new Date(value + 'Z');

    // Formatear la fecha para omitir los segundos y el cero inicial en la hora
    const formattedDate = date.toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',  // 'numeric' elimina el cero inicial en horas de una sola cifra
      minute: '2-digit',
      hour12: true,     // Forzar el formato de 12 horas
    });

    // Insertar un salto de línea si lineBreak es true
    return lineBreak ? formattedDate.replace(',', '<br>') : formattedDate;
  }
}
